import { AbstractControl } from '@angular/forms';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { ValidatorMessageObj } from '@yourcause/common/form-control-validation';


export function DataSetPercentageSumValidator (
  formFieldTableAndSubsetService: FormFieldTableAndSubsetService
) {
  return (control: AbstractControl) => {
    const val = control.value as ReferenceFieldsUI.TableResponseRowForUi[];
    const errorObj: ValidatorMessageObj = {
      dataSetMinMaxError: {
        i18nKey: 'common:textMustAddUpTo100',
        defaultValue: 'Must add up to 100%',
        errorMessage: 'Must add up to 100%'
      }
    };
    const sumOfFields = formFieldTableAndSubsetService.getFieldGroupTotal(val);

    const valid = sumOfFields === 100;

    return valid ? null : errorObj;
  };

}
