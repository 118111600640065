import { AbstractControl } from '@angular/forms';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { ValidatorMessageObj } from '@yourcause/common/form-control-validation';

export function DataSetTotalValidator (
  comparisonValue: number,
  customErrorMessage: string,
  defaultErrorMessage: string,
  formFieldTableAndSubsetService: FormFieldTableAndSubsetService
) {
  return (control: AbstractControl) => {
    const val = control.value as ReferenceFieldsUI.TableResponseRowForUi[];
    const errorMessage = customErrorMessage || defaultErrorMessage;
    const errorObj: ValidatorMessageObj = {
      mustEqualTotalValue: {
        i18nKey: '',
        defaultValue: '',
        errorMessage
      }
    };

    const sumOfFields = formFieldTableAndSubsetService.getFieldGroupTotal(val);

    const valid = sumOfFields === comparisonValue;

    return valid ? null : errorObj;
  };

}
