import { Injectable } from '@angular/core';
import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { SpecialHandlingService } from '@core/services/special-handling.service';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ApplicationFileService } from '@features/application-file/services/application-file.service';
import { BaseApplicationForLogic, ComponentTabIndexMap, FormAudience, FormComponentGroup, FormDefinitionComponent, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { SpecialHandling } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { InKindService } from '@features/in-kind/in-kind.service';
import { LogicState } from '@features/logic-builder/logic-builder.typing';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { YcFile } from '@yourcause/common/files';
import { isUndefined, uniq } from 'lodash';
import { ComponentHelperService } from '../component-helper/component-helper.service';
import { FormLogicService } from '../form-logic/form-logic.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { I18nService } from '@yourcause/common/i18n';
import { InputRegexService, MaskValidator, MinMaxArrayValidator, MinMaxValidator, MinMaxValidatorTypes, MinMaxWordsValidator, PatternValidator, RequiredArrayValidator, RequiredCheckboxValidator, RequiredCustomValidator } from '@yourcause/common/form-control-validation';
import { DataSetTotalValidator } from '@core/validators/data-set-total.validator';
import { DataSetRequireAllValidator } from '@core/validators/data-set-require-all.validator';
import { DataSetMinMaxValidator } from '@core/validators/data-set-min-max.validator';
import { IsValidTypes, ValidationTypes } from '@features/forms/component-configuration/component-configuration.typing';
import { SelectedOneOfValidator } from '@core/validators/selected-one-of.validator';
import { DataSetPercentageSumValidator } from '@core/validators/data-set-percentage-sum.validator';
import { HasSelectedQuantityValidator } from '@core/validators/has-selected-quantity.validator';
import { TotalValueOfValidator } from '@core/validators/total-value-of.validator';
import { MaxItemsValidator } from '@core/validators/max-items.validator';

export const MaxDesignationLength = 300;

@Injectable({ providedIn: 'root' })
export class FormValidationService {

  constructor (
    private componentHelper: ComponentHelperService,
    private formBuilder: TypeSafeFormBuilder,
    private formFieldHelperService: FormFieldHelperService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private applicationFileService: ApplicationFileService,
    private specialHandlingService: SpecialHandlingService,
    private formLogicService: FormLogicService,
    private inKindService: InKindService,
    private i18n: I18nService,
    private inputRegexService: InputRegexService
  ) { }

  /**
   * Get the Form Component Group
   *
   * @param tab: Tab
   * @param translations: Translations
   * @param inFormBuilder: Are we in the form builder?
   * @param readOnly: Is the form read only?
   * @param onManagerForm: Is this a manager form?
   * @param isForConfigModalPreview: Are we in the config modal?
   * @param isForSetValue: Is this for set value logic?
   * @returns the form group control map used to render the form
   */
  getFormComponentMap (
    tab: FormDefinitionForUi,
    translations: Record<string, string>,
    inFormBuilder: boolean,
    readOnly: boolean,
    onManagerForm: boolean,
    isForConfigModalPreview: boolean,
    isForSetValue: boolean
  ): FormComponentGroup {
    const components = this.componentHelper.getAllComponents([tab]);
    const formGroupBase = this.formBuilder.group({});
    components.forEach((component) => {
      const compKey = this.componentHelper.getAdaptedKeyFromComponentKey(component.key, isForSetValue, isForConfigModalPreview);
      const isRefField = this.componentHelper.isReferenceFieldComp(component.type);
      const isStandardField = this.componentHelper.isStandardComponent(component.type);
      const isEmployeeSsoField = this.componentHelper.isEmployeeSsoComponent(component.type);
      const isReportField = this.componentHelper.isReportFieldComp(component.type);
      if (isRefField) {
        this.addControlForRefFieldComponent(
          formGroupBase,
          component,
          translations,
          inFormBuilder,
          readOnly,
          onManagerForm,
          compKey
        );
      } else if (isStandardField) {
        this.addControlForStandardComponent(
          formGroupBase,
          component,
          translations,
          inFormBuilder,
          compKey
        );
      } else if (isEmployeeSsoField) {
        this.addControlForEmployeeSsoComponent(
          formGroupBase,
          component,
          inFormBuilder,
          compKey
        );
      } else if (isReportField) {
        this.addControlForReportComponent(
          formGroupBase,
          component,
          inFormBuilder,
          compKey
        );
      }
    });
  
    return formGroupBase;
  }

  /**
   * Add Custom Validator to Array
   *
   * @param inFormBuilder: Are we in the form builder?
   * @param component: Component
   * @param validators: Validators
   * @returns the adapted validators
   */
  addCustomValidatorToArray (
    inFormBuilder: boolean,
    component: FormDefinitionComponent,
    validators: ValidatorFn[]
  ) {
    if (
      !inFormBuilder &&
      (component.customValidation || !!component.validate?.custom)
    ) {
      validators = [
        ...validators,
        this.customValidator(component)
      ];
    }

    return validators;
  }

  /**
   * Custom Validator for Advanced Validation
   *
   * @param component: Component
   * @returns the custom validator
   */
  customValidator (component: FormDefinitionComponent) {
    return () => {
      const customValidationResult = component?.validate?.validationResult;
      const customValidationMessage = component?.validate?.customMessage;
      const hasValidationResult = !isUndefined(this.formLogicService.customValidationMap[component.key]);
      const hasCustomValidationError = hasValidationResult ?
        !this.formLogicService.customValidationMap[component.key] :
        false;
      if (hasCustomValidationError) {
        if (!!customValidationResult) {
          return {
            customValidationError: {
              errorMessage: customValidationMessage || customValidationResult
            }
          };
        } else {
          return {
            customValidationError: {
              errorMessage: component?.customValidation?.result ??
                customValidationMessage
            }
          };
        }
      }

      return null;
    };
  }

  /**
   * Adds the control for the reference field component
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param translations: Translations
   * @param inFormBuilder: Are we in the form builder?
   * @param readOnly: Is this form read (view) only?
   * @param onManagerForm: Is this a manager form?
   * @param compKey: Components'key
   */
  addControlForRefFieldComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    translations: Record<string, string>,
    inFormBuilder: boolean,
    readOnly: boolean,
    onManagerForm: boolean,
    compKey: string
  ) {
    const field = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
    let validators: ValidatorFn[] = [];
    if (!inFormBuilder) {
      validators = this.getValidatorsForReferenceFieldComponent(
        component,
        field,
        this.formFieldTableAndSubsetService.dataPointsMap,
        translations
      );
    }
    let controlValue: any = component.value;
    if (field.isRichText && field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea) {
      controlValue = {
        value: component.value,
        disabled: this.isCompDisabled(component, readOnly, field.formAudience, onManagerForm)
      };
    }
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(controlValue, this.addCustomValidatorToArray(inFormBuilder, component, validators))
    );
  }

  /**
   * Is the component disabled?
   *
   * @param component: Component
   * @param readOnly: Is this form read (view)  only?
   * @param fieldAudience: Field Audience
   * @param onManagerForm: Is this a manager form?
   * @returns 
   */
  isCompDisabled (
    component: FormDefinitionComponent,
    readOnly: boolean,
    fieldAudience: FormAudience,
    onManagerForm: boolean
  ) {
    if (readOnly === true) {
      return readOnly;
    } else {
      return (
          onManagerForm &&
          fieldAudience === FormAudience.APPLICANT
        ) ||
        this.componentHelper.isCompDisabled(component) ||
        // disable if manager field is on applicant form
        (
          !onManagerForm &&
          fieldAudience === FormAudience.MANAGER
        );
    }
  }

  getDataSetValidators (
    minResponses: number,
    maxResponses: number,
    validationTotal: number,
    allOptionsMustHaveResponse: boolean,
    collectionType: ReferenceFieldAPI.DataSetCollectionType,
    customErrorMessage: string,
    totalNumberOfRows: number
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    if (validationTotal) {
      const defaultError = this.i18n.translate(
        'common:textMustAddUpTo',
        {
          validationTotal
        },
        'Must add up to __validationTotal__'
      );
      const totalValidator = DataSetTotalValidator(
        validationTotal,
        customErrorMessage,
        defaultError,
        this.formFieldTableAndSubsetService
      );
      validators.push(totalValidator);
    }

    if (allOptionsMustHaveResponse) {
      const requireAllValidator = DataSetRequireAllValidator(
        totalNumberOfRows,
        customErrorMessage,
        this.i18n.translate(
          'common:textAllOptionsMustHaveResponse',
          {},
          'All options must have a response'
        )
      );
      validators.push(requireAllValidator);
    } else {
      if (minResponses) {
        const defaultErrorForCheckBox = this.i18n.translate(
          'common:textMustHaveAtLeastXSelected',
          {
            minResponses
          },
          'Must have at least __minResponses__ selected'
        );
        const defaultErrorForNumberOrPercent = this.i18n.translate(
          'common:textMustHaveAtLeastXValuesGreaterThanZero',
          {
            minResponses
          },
          'Must have at least __minResponses__ values greater than zero'
        );
        const defaultError = collectionType === ReferenceFieldAPI.DataSetCollectionType.YesOrNo ?
          defaultErrorForCheckBox :
          defaultErrorForNumberOrPercent;
        const minValidator = DataSetMinMaxValidator(
          minResponses,
          customErrorMessage,
          defaultError,
          true
        );
        validators.push(minValidator);
      }

      if (maxResponses) {
        const defaultErrorForCheckBox = this.i18n.translate(
          'common:textCannotHaveMoreThanXSelected',
          {
            maxResponses
          },
          'Cannot have more than __maxResponses__ selected'
        );
        const defaultErrorForNumberOrPercent = this.i18n.translate(
          'common:textCannotHaveMoreThanXValuesGreaterThanZero',
          {
            maxResponses
          },
          'Cannot have more than __maxResponses__ values greater than zero'
        );
        const  defaultError = collectionType === ReferenceFieldAPI.DataSetCollectionType.YesOrNo ?
          defaultErrorForCheckBox :
          defaultErrorForNumberOrPercent;
        const maxValidator = DataSetMinMaxValidator(
          maxResponses,
          customErrorMessage,
          defaultError,
          false
        );
        validators.push(maxValidator);
      }
    }


    if (collectionType === ReferenceFieldAPI.DataSetCollectionType.Percent) {
      const percentageSumValidator = DataSetPercentageSumValidator(this.formFieldTableAndSubsetService);
      validators.push(percentageSumValidator);
    }

    return validators;
  }

  /**
   *
   * @param component: form component
   * @param validationItemName: item name we are validating
   * @returns array of validators for the in kind component
   */
  getValidatorsForInKindComponent (
    component: FormDefinitionComponent,
    validationItemName: string,
    translations: Record<string, string> = {}
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    const validationType = component.validationType;
    const customError = this.getTranslatedCustomErrorMessage(
      component.validate?.customMessage || component.validationErrorMessage,
      translations
    );
    let validator: ValidatorFn;
    if (validationType) {
      switch (validationType) {
        case ValidationTypes.HasSelectedItem:
          validator = SelectedOneOfValidator(
            component.validationItem,
            component.willBeValid,
            customError,
            this.getDefaultInKindValidationMessage(
              component.validationType,
              component.validationAmount,
              validationItemName,
              component.willBeValid
            )
          );
          break;
        case ValidationTypes.HasSelectedQuantity:
          validator = HasSelectedQuantityValidator(
            component.validationAmount,
            component.willBeValid,
            customError,
            this.getDefaultInKindValidationMessage(
              component.validationType,
              component.validationAmount,
              validationItemName,
              component.willBeValid
            )
          );
          break;
        case ValidationTypes.QuantityEqualTo:
        case ValidationTypes.QuantityGreaterThan:
        case ValidationTypes.QuantityLessThan:
          validator = TotalValueOfValidator(
            component.validationType,
            component.validationAmount,
            component.willBeValid,
            customError,
            this.getDefaultInKindValidationMessage(
              component.validationType,
              component.validationAmount,
              validationItemName,
              component.willBeValid
            )
          );
          break;
      }
      validators.push(validator);
    }

    if (!!component.maxItems) {
      validator = MaxItemsValidator(
        component.maxItems,
        customError,
        this.i18n.translate(
          'common:textCannotSelectMoreThanNumberItems',
          {
            number: component.maxItems
          },
          'Cannot select more than __number__ items'
        )
      );
      validators.push(validator);
    }

    return validators;
  }

  /**
   *
   * @param component: form component
   * @param isCurrencyField: is currency field?
   * @param isCheckboxField: is checkbox field?
   * @param supportsMultiple: does the field support multiple values or is stored in an array?
   * @param translations: Translations
   * @param isDesignationField: Is Designation field?
   * @returns array of validators for component
   */
  getValidatorsForSimpleComponent (
    component: FormDefinitionComponent,
    isCurrencyField: boolean,
    isCheckboxField: boolean,
    supportsMultiple: boolean,
    translations: Record<string, string> = {},
    isDesignationField: boolean
  ): ValidatorFn[] {
    const customErrorMessage = this.getTranslatedCustomErrorMessage(
      component.validate?.customMessage || '',
      translations
    );
    let validators: ValidatorFn[] = [];
    if (isDesignationField) {
      validators.push(Validators.maxLength(MaxDesignationLength))
    }

    const isRequired = component.validate?.required ||
      component.required; // External API uses this attr
    if (isRequired) {
      validators.push(
        RequiredCustomValidator(
          customErrorMessage,
          this.i18n.translate(
            'common:textThisInputIsRequired',
            {},
            'This input is required'
          )
        )
      );
      if (isCurrencyField) {
        // When a currency field is required, it must be greater than zero
        validators.push(
          MinMaxValidator(
            'min',
            .01,
            customErrorMessage,
            this.i18n.translate(
              'common:textPleaseEnterANumberGreaterThanZero',
              {},
              'Amount must be greater than zero.'
            ),
            true
          )
        );
      } else if (isCheckboxField) {
        // When a checkbox field is required, it must be checked
        validators = [
          RequiredCheckboxValidator(
            customErrorMessage,
            this.i18n.translate(
              'common:textThisInputIsRequired',
              {},
              'This input is required'
            )
          )
        ];
      } else if (supportsMultiple) {
        validators = [
          RequiredArrayValidator(
            customErrorMessage,
            this.i18n.translate(
              'common:textThisInputIsRequired',
              {},
              'This input is required'
            )
          )
        ];
      }
    }

    return validators;
  }

  getTranslatedCustomErrorMessage (
    message: string,
    translations: Record<string, string> = {}
  ) {
    let customErrorMessage = message;
    customErrorMessage = translations[customErrorMessage] || customErrorMessage;

    return customErrorMessage;
  }

  /**
   * Adds the control for a standard component
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param translations: Translations
   * @param inFormBuilder: Are we in the form builder?
   * @param compKey: Component's key
   */
  addControlForStandardComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    translations: Record<string, string>,
    inFormBuilder: boolean,
    compKey: string
  ) {
    let value: any = component.value;
    let validators: ValidatorFn[] = [];
    switch (component.type) {
      case 'amountRequested':
        if (!inFormBuilder) {
          validators = this.getValidatorsForSimpleComponent(
            component,
            true,
            false,
            false,
            translations,
            false
          );
        }
        break;
      case 'inKindItems':
        if (!inFormBuilder) {
          const foundItem = this.inKindService.allItems?.find((item) => {
            return item.identification === component.validationItem;
          });
          validators = this.getValidatorsForInKindComponent(
            component,
            foundItem?.name ?? component.validationItem ?? '',
            translations
          );
        }
        break;
      case 'designation':
        if (!inFormBuilder) {
          validators = this.getValidatorsForSimpleComponent(
            component,
            false,
            false,
            false,
            translations,
            true
          );
        }
        break;
      case 'decision':
        if (!inFormBuilder) {
          validators = this.getValidatorsForSimpleComponent(
            component,
            false,
            false,
            false,
            translations,
            false
          );
        }
        break;
      case 'reviewerRecommendedFundingAmount':
        if (!inFormBuilder) {
          validators = this.getValidatorsForSimpleComponent(
            component,
            true,
            false,
            false,
            translations,
            false
          );
        }
        break;
      case 'specialHandling':
        const specialHandling = (value ?? {}) as SpecialHandling;
        let formControlFile: YcFile<File>[] = [];
        const file = this.applicationFileService.breakDownloadUrlDownToObject(
          specialHandling.fileUrl
        );
        if (!!file) {
          formControlFile = [new YcFile(
            file.fileName,
            null,
            specialHandling.fileUrl,
            +file.fileId
          )];
        }
        value = this.formBuilder.group({
          handlingName: specialHandling.name,
          address1: specialHandling.address1 || '',
          address2: specialHandling.address2 || '',
          city: specialHandling.city || '',
          stateProvRegCode: specialHandling.state || '',
          countryCode: specialHandling.country || '',
          postalCode: specialHandling.postalCode || '',
          file: [formControlFile],
          reason: specialHandling.reason || '',
          notes: specialHandling.notes || '',
          handlingOn: this.specialHandlingService.parentHasHandling(specialHandling)
        });
        break;
    }
  
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(
        value,
        this.addCustomValidatorToArray(inFormBuilder, component, validators)
      )
    );
  }

  /**
   * Adds the control for employee SSO components
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param inFormBuilder: Are we in the form builder?
   * @param compKey: Component's key
   */
  addControlForEmployeeSsoComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    inFormBuilder: boolean,
    compKey: string
  ) {
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(
        component.value,
        this.addCustomValidatorToArray(inFormBuilder, component, [])
      )
    );
  }

  /**
   * Adds the control for report components
   *
   * @param formGroupBase: Form Group Base
   * @param component: Component
   * @param inFormBuilder: Are we in the form builder?
   * @param compKey: Component's key
   */
  addControlForReportComponent (
    formGroupBase: TypeSafeFormGroup<unknown>,
    component: FormDefinitionComponent,
    inFormBuilder: boolean,
    compKey: string
  ) {
    const isNominationField = this.componentHelper.isReportNominationField(
      component.reportFieldDataOptions?.reportFieldObject
    );
    if (isNominationField) {
      compKey = this.componentHelper.getNominatorReportKey(component.reportFieldDataOptions.reportFieldDisplay);
    }
    formGroupBase.addControl(
      compKey,
      new UntypedFormControl(
        component.value,
        this.addCustomValidatorToArray(inFormBuilder, component, [])
      )
    );
  }

  /**
   * Sets the Validation Map on Init
   * 
   * @param formDefinition: Form Definition
   */
  setValidationMapOnInit (formDefinition: FormDefinitionForUi[]) {
    const allComps = this.componentHelper.getAllComponents(formDefinition);
    // All comps start off valid, until the validation is run and evaluated
    const validityMap = allComps.reduce((acc, comp) => {
      return {
        ...acc,
        [comp.key]: true
      };
    }, {} as Record<string, boolean>);
    this.formLogicService.setCustomValidationMap(validityMap);
    this.formLogicService.setFinalValidationMap(validityMap);
  }

  /**
   * Sets the Curent Validity State
   *
   * @param validityState: Validity State to Set
   */
  setCurrentValidityState (
    validityState: LogicState<BaseApplicationForLogic, boolean>
  ) {
    this.formLogicService.setCurrentValidityState(validityState);
  }

  /**
   * Sets the Form Validation Map
   *
   * @param formGroupMap: Form Group Map
   * @returns the validity changes
   */
  setFormValidationMap (
    formGroupMap: Record<number, FormComponentGroup>
  ) {
    const validationMap: Record<string, boolean> = {};
    let tabValidityChanges: number[] = [];
    Object.keys(formGroupMap).forEach((index) => {
      const group = formGroupMap[+index];
      for (const key in group.controls) {
        const control = group.controls[key];
        const previousValidity = this.formLogicService.finalValidationMap[key];
        const currentValidity = !control.invalid;
        if (previousValidity !== currentValidity) {
          tabValidityChanges.push(+index);
        }
        validationMap[key] = currentValidity;
      }
    });
    this.formLogicService.setFinalValidationMap(validationMap);

    return uniq(tabValidityChanges);
  }

  /**
   * Force Validation Updates
   *
   * @param compKeyValidityChanges: Comp Keys where validity has changed
   * @param componentTabIndexMap: Component tab index map
   * @param formGroupMap: Form group map
   */
  handleForcingValidationUpdates (
    compKeyValidityChanges: string[],
    componentTabIndexMap: ComponentTabIndexMap,
    formGroupMap: Record<number, FormComponentGroup>
  ) {
    if (compKeyValidityChanges.length > 0) {
      compKeyValidityChanges.forEach((compKey) => {
        const tabIndex = componentTabIndexMap[compKey].tabIndex;
        const control = formGroupMap[tabIndex].get(compKey);
        if (!!control) {
          control.updateValueAndValidity();
        }
      });
    }
  }

  /**
   *
   * @param component: form component
   * @param field: reference field
   * @param dataPointsMap: data points map
   * @returns array of validators for the reference field component
   */
  getValidatorsForReferenceFieldComponent (
    component: FormDefinitionComponent,
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    dataPointsMap: Record<number, ReferenceFieldsUI.DataPointForUI[]>,
    translations: Record<string, string> = {}
  ): ValidatorFn[] {
    const customErrorMessage = this.getTranslatedCustomErrorMessage(
      component.validate?.customMessage || '',
      translations
    );
    // Is Required
    const supportsMultiple = field.supportsMultiple ||
      field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload; // files are stored as arrays
    let validators = this.getValidatorsForSimpleComponent(
      component,
      field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency,
      field.type === ReferenceFieldsUI.ReferenceFieldTypes.Checkbox,
      supportsMultiple,
      translations,
      false
    );
    // Format Type
    if (!!field.formatType) {
      const { validator } = this.inputRegexService.getSelectedFormattingDetails(
        field.formatType,
        customErrorMessage
      );
      validators.push(validator);
    }
    // Subsets
    if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
      const response = this.getDataSetValidators(
        component.validate?.min,
        component.validate?.max,
        component.validationTotal,
        component.allOptionsMustHaveResponse,
        field.subsetCollectionType,
        customErrorMessage,
        dataPointsMap[
          field.referenceFieldId
        ].length
      );
      validators = [
        ...validators,
        ...response
      ];
    } else {
      // Min Max Validators
      const minMaxValidators = this.getMinMaxValidators(
        component,
        field,
        translations
      );
      validators = [
        ...validators,
        ...minMaxValidators
      ];
    }

    // Pattern
    if (component.validate?.pattern && !field.formatType) {
      const patternValidator = PatternValidator(
        component.validate.pattern,
        customErrorMessage,
        this.i18n.translate(
          'common:textValueDoesNotMatchRequiredPattern',
          {},
          'Value does not match the required pattern'
        )
      );
      validators.push(patternValidator);
    }
    // Input Mask
    if (component.inputMask) {
      const maskValidator = MaskValidator(
        this.getInputMaskRegExpForValidation(component.inputMask),
        customErrorMessage,
        this.i18n.translate(
          'common:textMustMatchTheMask',
          {},
          'Value does not match the required mask'
        )
      );
      validators.push(maskValidator);
    }

    return validators;
  }

  /**
   *
   * @param component: form component
   * @param field: reference field
   * @returns array of validators based on min / max validation settings
   */
  getMinMaxValidators (
    component: FormDefinitionComponent,
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    translations: Record<string, string> = {}
  ): ValidatorFn[] {
    const customErrorMessage = this.getTranslatedCustomErrorMessage(
      component.validate?.customMessage || '',
      translations
    );
    const validators: ValidatorFn[] = [];
    const numberValidatorsToCheck = [
      'min',
      'max',
      'minLength',
      'maxLength',
      'minWords',
      'maxWords'
    ] as MinMaxValidatorTypes[];
    numberValidatorsToCheck.forEach((attr) => {
      const value = component.validate ? component.validate[attr] : undefined;
      if (value || value === 0) {
        let validator: ValidatorFn;
        const isArray = field?.supportsMultiple ||
          [
            ReferenceFieldsUI.ReferenceFieldTypes.FileUpload,
            ReferenceFieldsUI.ReferenceFieldTypes.Table
          ].includes(field?.type);

        if (isArray) {
          validator = MinMaxArrayValidator(
            attr as 'min'|'max', // Arrays only support these attrs
            value,
            customErrorMessage,
            this.getDefaultMinMaxMessage(
              attr,
              value,
              field?.type,
              true
            )
          );
        } else {
          switch (attr) {
            case 'min':
            case 'max':
            case 'minLength':
            case 'maxLength':
              validator = MinMaxValidator(
                attr,
                value,
                customErrorMessage,
                this.getDefaultMinMaxMessage(
                  attr,
                  value,
                  field?.type,
                  false
                ),
                field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency
              );
              break;
            case 'minWords':
            case 'maxWords':
              if (
                field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea ||
                field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextField
              ) {
                validator = MinMaxWordsValidator(
                  attr,
                  value,
                  customErrorMessage,
                  this.getDefaultMinMaxMessage(
                    attr,
                    value,
                    field?.type,
                    false
                  )
                );
              }
              break;
          }
        }
        if (!!validator) {
          validators.push(validator);
        }

      }
    });

    return validators;
  }

  /**
   * Returns an input mask that is compatible with the input mask library.
   *
   * @param inputMask - The Form.io input mask.
   * @returns - The input mask for the mask library.
   */
  getInputMaskRegExpForValidation (inputMask: string) {
    const maskArray: (RegExp|string)[] = [];
    for (const char of inputMask) {
      switch (char) {
        case '0':
          maskArray.push(/\d/);
          break;
        case 'A':
        case 'a':
          maskArray.push(/[a-zA-Z]/);
          break;
        default:
          maskArray.push(char);
          break;
      }
    }


    return maskArray;
  }

  /**
   *
   * @param validationType: in kind validation type
   * @param validationAmount: in kind validation amount
   * @param validationItemName: in kind validation item name
   * @returns the default in kind validation error message
   */
  getDefaultInKindValidationMessage (
    validationType: ValidationTypes,
    validationAmount: number,
    validationItemName: string,
    isValidType: IsValidTypes
  ): string {
    const invalid = isValidType === IsValidTypes.Invalid;
    switch (validationType) {
      case ValidationTypes.HasSelectedItem:
        return this.i18n.translate(
          invalid ?
            'common:textCannotSelectItem' :
            'common:textMustSelectAtLeastOneOfItem',
          {
            name: validationItemName
          },
          invalid ?
            'Cannot select item __name__' :
            'Must select at least one __name__'
        );
      case ValidationTypes.HasSelectedQuantity:
        return this.i18n.translate(
          invalid ?
            'common:textCannotSelectNumberItems' :
            'common:textMustSelectNumberItems',
          {
            number: validationAmount
          },
          invalid ?
            'Cannot select __number__ items' :
            'Must select __number__ items'
        );
      case ValidationTypes.QuantityEqualTo:
        return this.i18n.translate(
          invalid ?
            'common:textCannotSelectNumberUnits' :
            'common:textMustSelectNumberUnits',
          {
            number: validationAmount
          },
          invalid ?
            'Cannot select _number__ units' :
            'Must select __number__ units'
        );
      case ValidationTypes.QuantityGreaterThan:
        return this.i18n.translate(
          invalid ?
            'common:textCannotSelectMoreThanNumberUnits' :
            'common:textMustSelectMoreThanNumberUnits',
          {
            number: validationAmount
          },
          invalid ?
            'Cannot select more than __number__ units' :
            'Must select more than __number__ units'
        );
      case ValidationTypes.QuantityLessThan:
        return this.i18n.translate(
          invalid ?
            'common:textCannotSelectLessThanNumberUnits' :
            'common:textMustSelectLessThanNumberUnits',
          {
            number: validationAmount
          },
          invalid ?
            'Cannot select less than __number__ units' :
            'Must select less than __number__ units'
        );
    }
  }

  /**
   *
   * @param attr: min max attribute
   * @param minMaxValue: min max value
   * @param fieldType: Form field type
   * @returns the default error message
   */
  getDefaultMinMaxMessage (
    attr: MinMaxValidatorTypes,
    minMaxValue: number,
    fieldType: ReferenceFieldsUI.ReferenceFieldTypes,
    isArray: boolean
  ): string {
    switch (attr) {
      case 'min':
        if (isArray) {
          const result = this.getMinMaxText(fieldType, true);

          return this.i18n.translate(
            result.i18nKey,
            {
              min: minMaxValue
            },
            result.i18nDefault
          );
        } else {
          return this.i18n.translate(
            'common:textMustBeAtLeastMin',
            {
              min: minMaxValue
            },
            'Must be at least __min__.'
          );
        }
      case 'max':
        if (isArray) {
          const result = this.getMinMaxText(fieldType, false);

          return this.i18n.translate(
            result.i18nKey,
            {
              max: minMaxValue
            },
            result.i18nDefault
          );
        } else {
          return this.i18n.translate(
            'common:textCannotBeMoreThanMax',
            {
              max: minMaxValue
            },
            'Cannot be more than __max__.'
          );
        }
       case 'minLength':
        return this.i18n.translate(
          'common:textMustBeAtLeastMinChars',
          {
            min: minMaxValue
          },
          'Must be at least __min__ characters.'
        );
      case 'maxLength':
        return this.i18n.translate(
          'common:textCannotBeMoreThanMaxChars',
          {
            max: minMaxValue
          },
          'Cannot be more than __max__ characters'
        );
      case 'minWords':
        return this.i18n.translate(
          'common:textMustHaveAtLeastMinWords',
          {
            min: minMaxValue
          },
          'Must have at least __min__ words.'
        );
      case 'maxWords':
        return this.i18n.translate(
          'common:textCannotBeMoreThanMaxWords',
          {
            max: minMaxValue
          },
          'Cannot be more than __max__ words.'
        );
    }
  }

  /**
   *
   * @param fieldType: Field Type
   * @param isMin: is minimum requirement? vs max
   * @returns the i18nKey and i18nDefault
   */
  getMinMaxText (
    fieldType: ReferenceFieldsUI.ReferenceFieldTypes,
    isMin: boolean
  ) {
    let i18nKey: string;
    let i18nDefault: string;
    switch (fieldType) {
      case ReferenceFieldsUI.ReferenceFieldTypes.FileUpload:
        i18nKey = isMin ? 'common:textAtLeastMinFilesRequired' : 'common:textCannotHaveMoreThanMaxFiles';
        i18nDefault = isMin ? 'At least __min__ file(s) required' : 'Cannot have more than __max__ files';
        break;
      case ReferenceFieldsUI.ReferenceFieldTypes.Table:
      case ReferenceFieldsUI.ReferenceFieldTypes.Subset:
        i18nKey = isMin ? 'common:textAtLeastMinRowsRequired' : 'common:textCannotHaveMoreThanMaxRows';
        i18nDefault = isMin ? 'At least __min__ row(s) required' : 'Cannot have more than __max__ rows';
        break;
      default:
        i18nKey = isMin ? 'common:textMustSelectMinOf' : 'common:textCannotHaveMoreThanMaxSelected';
        i18nDefault = isMin ? 'Must select a minimum of __min__' : 'Cannot select more than __max__';
        break;
    }

    return {
      i18nKey,
      i18nDefault
    };
  }


  /**
   *
   * @param designation string value
   * @returns validity state
   */
   getDesignationValidity (designation: string) {
    return !(designation && designation.length > MaxDesignationLength);
  }
}

