import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { SpinnerService } from '@core/services/spinner.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormTypes, ReportFieldDataOptions } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { ComponentHelperService, NominationFormObject } from '@features/forms/services/component-helper/component-helper.service';
import { ReportFieldService } from '@features/forms/services/report-field/report-field.service';
import { AdHocReportingDefinitions } from '@features/reporting/services/ad-hoc-reporting-definitions.service';
import { AdHocReportingService } from '@features/reporting/services/ad-hoc-reporting.service';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { Subscription } from 'rxjs';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';

@Component({
  selector: 'gc-report-field-data-options-widget',
  templateUrl: './report-field-data-options-widget.component.html',
  styleUrls: ['./report-field-data-options-widget.component.scss']
})
export class ReportFieldDataOptionsComponent extends BaseComponentConfigSettingsComponent implements OnInit, OnDestroy {
  @Input() reportFieldDataOptions: ReportFieldDataOptions;
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<ReportFieldDataOptions>();

  formGroup: TypeSafeFormGroup<ReportFieldDataOptions>;
  objectOptions: TypeaheadSelectOption[];
  isNominationForm = false;
  displayOptions: TypeaheadSelectOption[] = [];
  hasNominations = this.clientSettingService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasNominations);
  buckets: AdHocReportingUI.ColumnBucket[];
  NominationFormObject = NominationFormObject;
  sub = new Subscription();
  nominationFormOptions = this.formService.nominationFormOptions;
  isEdit = false;
  isEditAndNoFormFound = false;
  isNominationField = false;

  constructor (
    private clientSettingService: ClientSettingsService,
    private formBuilder: TypeSafeFormBuilder,
    private adHocReportingService: AdHocReportingService,
    private formBuilderService: FormBuilderService,
    private formService: FormsService,
    private spinnerService: SpinnerService,
    private arrayHelper: ArrayHelpersService,
    private reportFieldService: ReportFieldService,
    private adHocReportingDefinitions: AdHocReportingDefinitions,
    private componentHelper: ComponentHelperService,
    private formFieldHelperService: FormFieldHelperService
  ) {
    super();
  }

  ngOnInit () {
    const config = this.reportFieldDataOptions;
    this.isEdit = !!config?.reportFieldObject && !!config?.reportFieldDisplay;
    this.setIsNominationField(config?.reportFieldObject, true);
    this.setOptions();
    this.setupFormGroup();
    this.setDisplayOptions(true);
    if (!this.isEdit) {
      this.handleDataChange();
    }
  }

  setIsNominationField (object: string, isInit: boolean) {
    this.isNominationField = this.componentHelper.isReportNominationField(object);
    if (isInit && this.isEdit && this.isNominationField) {
      const nominationFormId = this.reportFieldDataOptions?.nominationFormId;
      const exists = this.nominationFormOptions.some((form) => form.value === +nominationFormId);
      if (!exists) {
        this.isEditAndNoFormFound = true;
      }
    } else {
      this.isEditAndNoFormFound = false;
    }
  }

  setupFormGroup () {
    const config = this.reportFieldDataOptions;
    const object = config?.reportFieldObject || 'application';
    const nominationFormId = config?.nominationFormId;
    const foundForm = !!nominationFormId &&
      this.nominationFormOptions.find((form) => form.value === +nominationFormId);
    this.formGroup = this.formBuilder.group<ReportFieldDataOptions> ({
      reportFieldObject: [
        object,
        Validators.required
      ],
      nominationFormId: !!foundForm ? nominationFormId : null,
      reportFieldDisplay: [
        config?.reportFieldDisplay,
        Validators.required
      ]
    });
    this.sub.add(this.formGroup.statusChanges.subscribe(() => {
      this.isValidChange.emit(this.formGroup.valid);
    }));
  }

  setOptions () {
    const formType = this.formService.getFormTypeFromFormId(this.formBuilderService.currentFormBuilderFormId);
    this.isNominationForm = formType === FormTypes.NOMINATION;
    const includeNominationFormObject = !this.isNominationForm &&
      this.hasNominations &&
      this.nominationFormOptions.length > 0;
    this.buckets = this.reportFieldService.getReportFieldBuckets(this.isNominationForm, includeNominationFormObject);
    this.objectOptions = this.buckets.map((bucket) => {
      return {
        label: bucket.display,
        value: bucket.property
      };
    });
  }

  objectChanged (object: string) {
    this.setIsNominationField(object, false);
    this.setDisplayOptions(false);
  }

  handleDataChange () {
    this.onChange.emit(this.formGroup.value);
  }

  async setDisplayOptions (isInit: boolean) {
    const object = this.formGroup.value.reportFieldObject;
    if (this.isNominationField && !this.isEditAndNoFormFound) {
      // Related nominator selection requires an extra select of the Nomination form,
      // Before we can display the available fields
      this.spinnerService.startSpinner();
      const formId = this.formGroup.value.nominationFormId;
      if (!!formId) {
        const categoryMap = await this.adHocReportingService.resolveFormInfo(
          [formId],
          this.adHocReportingDefinitions.application,
          AdHocReportingUI.Usage.REPORT_FIELD
        );
        this.displayOptions = this.arrayHelper.sort(
          Object.keys(categoryMap).reduce((acc, categoryId) => {
            const comps = categoryMap[categoryId].map((comp) => {
              return {
                label: comp.name,
                value: comp.key
              };
            });

            return [
              ...acc,
              ...comps
            ];
          }, [] as TypeaheadSelectOption[]),
          'label'
        );
      } else if (isInit && !!this.reportFieldDataOptions.reportFieldDisplay) {
        this.displayOptions = [{
          label: this.formFieldHelperService.referenceFieldMap[this.reportFieldDataOptions.reportFieldDisplay]?.name ??
            this.reportFieldDataOptions.reportFieldDisplay,
          value: this.reportFieldDataOptions.reportFieldDisplay
        }];
      } else {
        this.displayOptions = [];
      }
      this.spinnerService.stopSpinner();
    } else {
      const bucket = this.buckets.find((_bucket) => _bucket.property === object);
      this.displayOptions = bucket.columns.filter((option) => {
        // Only return options that can be used as report fields
        const canBeUsedAsReportField = option.definition.canBeUsedAsReportField;
        
        if (this.isNominationForm) {
          return !option.definition.notApplicableToNominations && canBeUsedAsReportField;
        }

        return canBeUsedAsReportField;
      }).map((option) => {
        return {
          label: option.definition.display,
          value: option.definition.column
        };
      });
      if (this.isNominationField && !!this.formGroup.value.reportFieldDisplay) {
        const itemExists = this.displayOptions.some((item) => {
          return item.value === this.formGroup.value.reportFieldDisplay;
        });
        if (!itemExists) {
          // If the reportFieldDisplay is not in the list of display options, add it to the list
          this.displayOptions.push({
            label: this.formFieldHelperService.referenceFieldMap[this.formGroup.value.reportFieldDisplay]?.name ??
              this.formGroup.value.reportFieldDisplay,
            value: this.formGroup.value.reportFieldDisplay
          });
        }
      }
    }
    
    const reportFieldDisplay = this.formGroup?.value?.reportFieldDisplay;
    if (!!reportFieldDisplay) {
      // If items change, check if reportFieldDisplay should be reset
      const itemExists = this.displayOptions.some((item) => {
        return item.value === reportFieldDisplay;
      });
      if (!itemExists) {
        this.formGroup.get('reportFieldDisplay').setValue(null);
      }
    }
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
