import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyService } from '@core/services/currency.service';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { SpinnerService } from '@core/services/spinner.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { OpenCloseBudgetAPI } from '@core/typings/api/open-close-budget.typing';
import { BudgetFundingSourceAuditTypes as AuditTypes, Budget, BudgetDetail, BudgetDetailMap, BudgetDrilldownInfo, BudgetForImport, BudgetFundingSource, BudgetFundingSourceAudit, BudgetFundingSourceCombo, BudgetFundingSourceSave, BudgetImport, BudgetImportModel, BudgetImportNoSources, BudgetImportNoSourcesModel, BudgetImportNoSourcesWithProcesserModel, BudgetSave, BulkUpdateBudgetsPayload, ExportBudgetFundingSourceAudit, FundingSource, FundingSourceAllocationsImport, FundingSourceDrilldownInfo, FundingSourceForApi, FundingSourceTypes, RemainingAmountBudgetMap } from '@core/typings/budget.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { PaymentStatus } from '@core/typings/status.typing';
import { ApplicationBudgetInfo } from '@features/budget-assignments/budget-assignments.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FundingSourceDetailFlyoutComponent } from '@features/funding-sources/funding-source-detail-flyout/funding-source-detail-flyout.component';
import { NonprofitService } from '@features/nonprofit/nonprofit.service';
import { ProgramService } from '@features/programs/services/program.service';
import { SystemTagsService } from '@features/system-tags/system-tags.service';
import { SystemTags } from '@features/system-tags/typings/system-tags.typing';
import { DynamicValidationService, OrganizationEligibleForGivingStatus, PaginationOptions, SimpleStringMap, TableRepositoryFactory } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DateService } from '@yourcause/common/date';
import { FileService, TableDataDownloadFormat } from '@yourcause/common/files';
import { FlyoutService } from '@yourcause/common/flyout';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService, ModalFactory } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { unparse } from 'papaparse';
import { BudgetDetailFlyoutComponent } from './budget-detail-flyout/budget-detail-flyout.component';
import { BudgetResources } from './budget.resources';
import { BudgetState } from './budget.state';
import { CreateEditBudgetModalResponse } from './create-edit-budget-modal/create-edit-budget-modal.component';
import { OpenCloseBudgetModalComponent } from './open-close-budget-modal/open-close-budget-modal.component';
import { IsNumber, ServiceValidator, TopLevelValidatorReturn, Transform, ValidatorExtras, ValidatorReturn, createTopLevelValidator } from '@yourcause/common/form-control-validation';
import { MoneyService } from '@yourcause/common/currency';
export const Budgets_Table_Key = 'BUDGETS_TABLE';
export const Funding_Source_Table_Key = 'FUNDING_SOURCES_TABLE';
export const Budgets_Audit_Trail_Table_Key = 'BUDGET_AUDIT_TRAIL';

@AttachYCState(BudgetState)
@Injectable({ providedIn: 'root' })
export class BudgetService extends BaseYCService<BudgetState> {

   constructor (
    private budgetResources: BudgetResources,
    private notifier: NotifierService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private programService: ProgramService,
    private currencyService: CurrencyService,
    private clientSettingsService: ClientSettingsService,
    private fileService: FileService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private router: Router,
    private systemTagsService: SystemTagsService,
    private dynamicValidationService: DynamicValidationService,
    private flyoutService: FlyoutService,
    private spinnerService: SpinnerService,
    private modalFactory: ModalFactory,
    private tableFactory: TableRepositoryFactory,
    private dateService: DateService,
    private nonprofitService: NonprofitService,
    private gcFlyoutService: GcFlyoutService,
    private moneyService: MoneyService
  ) {
    super();
  }

  get budgets () {
    return this.get('budgets');
  }

  get budgetsForDashboard () {
    return this.get('budgetsForDashboard');
  }

  get budgetNameMap () {
    return this.get('budgetNameMap');
  }

  get budgetDetailMap () {
    return this.get('budgetDetailMap');
  }

  get simpleBudgetMap () {
    return this.get('simpleBudgetMap');
  }

  get budgetTagsMap () {
    return this.get('budgetTagsMap');
  }

  get fundingSourceMap () {
    return this.get('fundingSourceMap');
  }

  get allFundingSources () {
    return this.openFundingSources?.concat(this.closedFundingSources);
  }

  get openFundingSources () {
    return this.get('openFundingSources');
  }

  get closedFundingSources () {
    return this.get('closedFundingSources');
  }

  get unallocatedSourceMap () {
    return this.get('unallocatedSourceMap');
  }

  get allBudgetOptions () {
    return this.get('allBudgetOptions');
  }

  get allSourceOptions () {
    return this.get('allSourceOptions');
  }

  get myBudgetOptions () {
    return this.get('myBudgetOptions');
  }

  get budgetDrilldownMap () {
    return this.get('budgetDrilldownMap');
  }

  get fundingSourceDrilldownMap () {
    return this.get('fundingSourceDrilldownMap');
  }

  get mySourceOptions () {
    return this.get('mySourceOptions');
  }

  get cashBudgetOptions () {
    return this.get('cashBudgetOptions');
  }

  get cashSourceOptions () {
    return this.get('cashSourceOptions');
  }

  get inKindBudgetOptions () {
    return this.get('inKindBudgetOptions');
  }

  get inKindSourceOptions () {
    return this.get('inKindSourceOptions');
  }

  get noInKindSourcesExist () {
    return (this.openFundingSources || []).filter((source) => {
      return source.type === FundingSourceTypes.UNITS;
    }).length === 0;
  }

  get noCashSourcesExist () {
    return (this.openFundingSources || []).filter((source) => {
      return source.type === FundingSourceTypes.DOLLARS;
    }).length === 0;
  }

  get budgetFundingSources () {
    return this.get('budgetFundingSources');
  }

  get budgetAuditTrailMap () {
    return this.get('budgetAuditTrailMap');
  }

  getHideFundingSources () {
    return this.clientSettingsService.hideFundingSources;
  }

  getBudgetDetailFromMap (id: number|string) {
    return this.budgetDetailMap[id];
  }

  addBudgetDetailToMap (
    budget: BudgetDetail,
    isNew = false
  ) {
    const id = isNew ? 'new' : budget.id;
    this.set('budgetDetailMap', {
      ...this.budgetDetailMap,
      [id]: budget
    });
  }

  removeNewBudgetDetailFromMap () {
    const map: BudgetDetailMap = {
      ...this.budgetDetailMap,
      ['new']: undefined
    };
    this.set('budgetDetailMap', map);
  }

  async resetBudgetDetail (budgetId: number) {
    const detail  = await this.getBudgetDetail(budgetId);
    this.addBudgetDetailToMap(detail);
  }

  setBudgetsOnState (budgets: Budget[]) {
    this.set('budgets', budgets);
  }

  setClosedBudgets (budgets: Budget[]) {
    this.set('closedBudgets', budgets);
  }

  setOpenBudgets (budgets: Budget[]) {
    this.set('openBudgets', budgets);
  }

  setClosedFundingSources (fundingSources: FundingSource[]) {
    this.set('closedFundingSources', fundingSources);
  }

  setOpenFundingSources (fundingSources: FundingSource[]) {
    this.set('openFundingSources', fundingSources);
  }

  setFundingSourceMap (map: {
    [f: number]: FundingSource;
  }) {
    this.set('fundingSourceMap', map);
  }

  setBudgetDrilldownMap (id: number, drilldownInfo: BudgetDrilldownInfo) {
    const map = {
      ...this.budgetDrilldownMap,
      [id]: drilldownInfo
    };

    this.set('budgetDrilldownMap', map);
  }

  setFundingSourceDrilldownMap (id: number, drilldownInfo: FundingSourceDrilldownInfo) {
    const map = {
      ...this.get('fundingSourceDrilldownMap'),
      [id]: drilldownInfo
    };
    this.set('fundingSourceDrilldownMap', map);
  }

  getFundingSourceDetail (fundingSourceId: number) {
    return this.allFundingSources.find((fundingSource) => {
      return fundingSource.id === fundingSourceId;
    });
  }

  isSameBudgetAndSource (
    budgetIdOne: number,
    budgetIdTwo: number,
    fsIdOne: number,
    fsIdTwo: number
  ) {
    return (budgetIdOne === budgetIdTwo) && (fsIdOne === fsIdTwo);
  }

  async setBudgets () {
    await this.systemTagsService.resolveSystemTags();
    if (!this.budgets) {
      const budgets = await this.budgetResources.getBudgets();
      const budgetNameMap: {
        [x: number]: string;
      } = {};
      budgets.forEach((budget: Budget) => {
        budgetNameMap[budget.id] = budget.name;
      });
      this.set('budgetNameMap', budgetNameMap);
      this.setBudgetsOnState(this.arrayHelper.sort(budgets, 'name'));
      const closedBudgets = budgets.filter((budget) => {
        return budget.isClosed;
      });
      const openBudgets = budgets.filter((budget) => {
        return !budget.isClosed;
      });
      this.setClosedBudgets(this.arrayHelper.sort(closedBudgets, 'name'));
      this.setOpenBudgets(this.arrayHelper.sort(openBudgets, 'name'));
      this.setSimpleBudgetMap();
      this.setBudgetTagsMap();

      return budgets;
    }

    return this.budgets;
  }

  async setBudgetFundingSources () {
    const fundingSources = await this.budgetResources.getBudgetFundingSources();
    this.set('budgetFundingSources', fundingSources);
  }

  setSimpleBudgetMap () {
    this.budgets.forEach((budget) => {
      this.set('simpleBudgetMap', {
        ...this.simpleBudgetMap,
        [budget.id]: budget
      });
    });
  }

  setBudgetTagsMap () {
    this.budgets.forEach((budget) => {
      this.set('budgetTagsMap', {
        ...this.budgetTagsMap,
        [budget.id]: this.arrayHelper.sort(budget.budgetTags.map((id) => {
          return this.systemTagsService.allTags.find(tag => tag.id === id);
        }), 'name')
      });
    });
  }

  /**
   * Sets the Budget Drilldown Info if not already set on the state
   *
   * @param id: Budget ID
   * @returns the budget drilldown info
   */
  async setBudgetDrilldownInfo (id: number) {
    if (!this.budgetDrilldownMap[id]) {
      let drilldownInfo = await this.budgetResources.getBudgetDrilldownInfo(id);
      drilldownInfo = this.adaptDrilldownInfo(drilldownInfo);

      this.setBudgetDrilldownMap(id, drilldownInfo);
    }

    return this.budgetDrilldownMap[id];
  }

  /**
   * Adapts the drilldown info to include grantProgramName
   *
   * @param drilldownInfo: Drilldown Info
   * @returns the adapted drilldown info with grantProgramName
   */
  adaptDrilldownInfo (drilldownInfo: BudgetDrilldownInfo) {
    return {
      budgetFundingSourceDetailModel: drilldownInfo.budgetFundingSourceDetailModel,
      grantProgramInfos: this.arrayHelper.sort(drilldownInfo.grantProgramInfos.map((program) => {
        const map = this.programService.programTranslationMap[program.grantProgramId];
        const grantProgramName = map && map.Name ? map.Name : program.grantProgramName;

        return {
          ...program,
          grantProgramName
        };
      }), 'grantProgramName')
    };
  }

  async getFundingSourceDrilldownInfo (id: number) {
    if (!this.get('fundingSourceDrilldownMap')[id]) {
      const drilldownInfo = await this.budgetResources.getFundingSourceDrilldownInfo(id);
      this.setFundingSourceDrilldownMap(id, drilldownInfo);

      return this.get('fundingSourceDrilldownMap')[id];
    } else {
      return this.get('fundingSourceDrilldownMap')[id];
    }
  }

  async getFundingSourcesForDashboard (force = false) {
    if (force || !this.get('fundingSourcesForDashboard')) {
      const fundingSources = await this.budgetResources.getFundingSourcesForDashboard();
      const nonArchivedFundingSources = fundingSources.filter((fs) => {
        return !fs.isArchived;
      });
      this.set('fundingSourcesForDashboard', nonArchivedFundingSources);

      return nonArchivedFundingSources;
    }

    return this.get('fundingSourcesForDashboard');
  }

  async getBudgetsForDashboard () {
    if (!this.get('budgetsForDashboard')) {
      const budgets = await this.budgetResources.getBudgetsForDashboard();
      this.set('budgetsForDashboard', budgets);
    }
  }

  resetBudgetsForDashboard () {
    this.set('budgetsForDashboard', undefined);
  }

  async setBudgetForDashboard (id: number) {
    const [
      detail,
      stats,
      programs,
      fundingSources
    ] = await Promise.all([
      this.getBudgetDetail(id),
      this.budgetResources.getBudgetStats([id]),
      this.budgetResources.getProgramsForBudgetDashboard(id),
      this.budgetResources.getFundingSourcesForBudgetDashboard(id)
    ]);
    programs.forEach((program) => {
      const map = this.programService.programTranslationMap[program.programId];
      program.programName = map && map.Name ? map.Name : program.programName;
    });
    const sources =  fundingSources.filter((source) => {
      if (source.isOverage) {
        return source.totalSpent > 0;
      }

      return source;
    });

    this.set('budgetDashboardMap', {
      ...this.get('budgetDashboardMap'),
      [id]: {
        detail,
        stats,
        programs: this.arrayHelper.sort(
          programs, 'paymentsAmount', true
        ),
        sources: this.arrayHelper.sort(
          sources, 'totalRemaining', true
        )
      }
    });
  }

  async setFundingSourceForDashboard (id: number) {
    const [
      stats,
      budgets
    ] = await Promise.all([
      this.budgetResources.getFundingSourceStats([id]),
      this.budgetResources.getBudgetsByFundingSourceID(id)
    ]);
    this.set('fundingSourceDashboardMap', {
      ...this.get('fundingSourceDashboardMap'),
      [id]: {
        stats,
        budgets
      }
    });
  }

  async setBudgetOptions () {
    const [
      segmented
    ] = await Promise.all([
      this.budgetResources.getBudgetsSegmented(),
      this.setBudgets()
    ]);
    const myBudgetOptions: TypeaheadSelectOption[] = [];
    const cashOptions = this.budgets.filter((budget) => {
      return budget.fundingSourceType === FundingSourceTypes.DOLLARS;
    }).map((budget) => {
      const budgetOption = {
        label: budget.name,
        value: budget.id
      };
      if (segmented.includes(budget.id)) {
        myBudgetOptions.push(budgetOption);
      }

      return budgetOption;
    });
    const inKindOptions = this.budgets.filter((budget) => {
      return budget.fundingSourceType === FundingSourceTypes.UNITS;
    }).map((budget) => {
      const budgetOption = {
        label: budget.name,
        value: budget.id
      };
      if (segmented.includes(budget.id)) {
        myBudgetOptions.push(budgetOption);
      }

      return budgetOption;
    });
    this.set(
      'allBudgetOptions',
      this.arrayHelper.sort([
        ...cashOptions,
        ...inKindOptions
      ], 'label')
    );
    this.set(
      'cashBudgetOptions',
      this.arrayHelper.sort(cashOptions, 'label')
    );
    this.set(
      'inKindBudgetOptions',
      this.arrayHelper.sort(inKindOptions, 'label')
    );
    this.set(
      'myBudgetOptions',
      this.arrayHelper.sort(myBudgetOptions, 'label')
    );
  }

  async setSourceOptions () {
    const [
      segmented
    ] = await Promise.all([
      this.budgetResources.getFundingSourcesSegmented(),
      this.setFundingSources()
    ]);
    const cashOptions = this.openFundingSources.filter((source) => {
      return source.type === FundingSourceTypes.DOLLARS;
    }).map((source) => {
      return {
        label: source.name,
        value: source.id
      };
    });
    const inKindOptions = this.openFundingSources.filter((source) => {
      return source.type === FundingSourceTypes.UNITS;
    }).map((source) => {
      return {
        label: source.name,
        value: source.id
      };
    });
    const mySourceOptions: TypeaheadSelectOption[] = [];
    this.allFundingSources.forEach((source) => {
        if (segmented.includes(source.id)) {
          const sourceOption = {
            label: source.name,
            value: source.id
          };
          mySourceOptions.push(sourceOption);
        }
      });
    this.set(
      'allSourceOptions',
      this.arrayHelper.sort([
        ...cashOptions,
        ...inKindOptions
      ], 'label')
    );
    this.set(
      'cashSourceOptions',
      this.arrayHelper.sort(cashOptions, 'label')
    );
    this.set(
      'inKindSourceOptions',
      this.arrayHelper.sort(inKindOptions, 'label')
    );
    this.set(
      'mySourceOptions',
      this.arrayHelper.sort(mySourceOptions, 'label')
    );
  }

  async setAllOptions () {
    await Promise.all([
      this.setBudgetOptions(),
      this.setSourceOptions()
    ]);
  }

  getBudgetDetail (budgetId: number) {
    return this.budgetResources.getBudget(budgetId);
  }

  async setBudgetDetail (budgetId: number) {
    let budgetDetail = this.getBudgetDetailFromMap(budgetId);
    if (!budgetDetail) {
      budgetDetail = await this.getBudgetDetail(budgetId);
      this.addBudgetDetailToMap(budgetDetail);
    }

    return budgetDetail;
  }

  async resetBudgets () {
    this.setBudgetsOnState(undefined);
    await this.setBudgets();
  }

  async resetBudgetAuditTrail (budgetId: number) {
    this.clearBudgetAuditTrail(budgetId);
    await this.setBudgetAuditTrailRecords(budgetId);
  }

  clearBudgetAuditTrail (budgetId: number) {
    this.set('budgetAuditTrailMap', {
      ...this.budgetAuditTrailMap,
      [budgetId]: undefined
    });
  }

  clearBudgetDetailMap (budgetId: number) {
    this.set('budgetDetailMap', {
      ...this.budgetDetailMap,
      [budgetId]: null
    });
  }

  clearBudgetDetails (budgetIds: number[]) {
    budgetIds.forEach((budgetId) => {
      this.clearBudgetDetailMap(budgetId);
      this.clearBudgetAuditTrail(budgetId);
    });
  }

  async getUnitCostMap () {
    const unitCostMap: {
      [x: string]: number;
    } = {};
    await this.setFundingSources();
    this.allFundingSources.forEach((fs) => {
      unitCostMap[fs.id] = fs.unitCost;
    });

    return unitCostMap;
  }

  async setFundingSources () {
    if (!this.openFundingSources || !this.closedFundingSources) {
      const fundingSources = await this.budgetResources.getFundingSources();
      const openFS = fundingSources.filter((fs) => {
        return !fs.isClosed;
      });
      const closedFS = fundingSources.filter((fs) => {
        return fs.isClosed;
      });
      this.setOpenFundingSources(openFS);
      this.setClosedFundingSources(closedFS);
      this.setupFundingSourceMap();
      this.setFundingSourceUnallocatedMap();
    }
  }

  setupFundingSourceMap () {
    const map: {
      [x: number]: FundingSource;
    } = {};
    this.allFundingSources.forEach((source: FundingSource) => {
      map[source.id] = source;
    });
    this.setFundingSourceMap(map);
  }

  async resetFundingSources () {
    this.setOpenFundingSources(undefined);
    this.setClosedFundingSources(undefined);
    this.set('unallocatedSourceMap', undefined);
    await this.setFundingSources();
  }

  setFundingSourceUnallocatedMap () {
    const map: SimpleStringMap<number> = {};
    this.allFundingSources.forEach((source) => {
      map[source.id] = source.amountUnallocated;
    });
    this.set('unallocatedSourceMap', map);
  }

  // ** For Simple Award Modal * //

  async getBudgetsFilteredByProgramAndProcessor (
    programId: number,
    isEligibleForGivingByYc: boolean,
    allRegAuthoritiesAreValid: boolean,
    cycleId: number,
    existingBudgetId?: number
  ) {
    if (!allRegAuthoritiesAreValid) {
      return {
        allowCash: false,
        allowUnits: false,
        filteredBudgets: []
      };
    }
    const cycle = await this.programService.getCycleFromProgram(programId, cycleId);
    let allowCash = false;
    let allowUnits = false;
    // Filter for Program
    const filteredBudgets = this.budgets.filter((budget) => {
      if (existingBudgetId === budget.id) {
        return true;
      }

      return cycle.budgetIds.includes(budget.id);
    }).map((budget) => {
      if (budget.fundingSourceType === FundingSourceTypes.DOLLARS) {
        allowCash = true;
      } else if (budget.fundingSourceType === FundingSourceTypes.UNITS) {
        allowUnits = true;
      }

      return budget;
    });

    await Promise.all(filteredBudgets.map(async (budget) => {
      await this.setBudgetDetail(budget.id);
    }));
    const budgetDetails = filteredBudgets.map((budget) => {
      return this.getBudgetDetailFromMap(budget.id);
    });
    if (!isEligibleForGivingByYc) {
      const cashPassedVetting = budgetDetails.some((budget) => {
        const type = budget.budgetFundingSources[0].fundingSourceType;

        return type === FundingSourceTypes.DOLLARS &&
          budget.hasClientProcessingType;
      });
      const inKindPassedVetting = budgetDetails.some((budget) => {
        const type = budget.budgetFundingSources[0].fundingSourceType;

        return type === FundingSourceTypes.UNITS &&
          budget.hasClientProcessingType;
      });
      allowCash = allowCash && cashPassedVetting;
      allowUnits = allowUnits && inKindPassedVetting;
    }

    return {
      allowCash,
      allowUnits,
      filteredBudgets
    };
  }

  async getDefaultBudgetForNewPayment (
    options: TypeaheadSelectOption<BudgetFundingSourceCombo>[],
    isUnits: boolean,
    programId: number,
    cycleId: number,
    assignedBudgetId?: number,
    assignedFsId?: number
  ) {
    const cycle = await this.programService.getCycleFromProgram(programId, cycleId);
    let budgetId = !isUnits ?
      cycle.defaultCashBudgetId :
      cycle.defaultInKindBudgetId;
    let fsId = !isUnits ?
      cycle.defaultCashFundingSourceId :
      cycle.defaultInKindFundingSourceId;
    if (!isUnits && assignedBudgetId && assignedFsId) {
      budgetId = assignedBudgetId;
      fsId = assignedFsId;
    }
    const found = options.find(option => {
      const combo = option.value;

      return (combo.budget.id === budgetId) &&
        combo.fundingSource.fundingSourceId === fsId;
    });
    if (found) {
      return found.value;
    }
    let budgetWithFunds: BudgetFundingSourceCombo;
    options.forEach((option) => {
      const combo = option.value;
      if (!budgetWithFunds && !!combo.fundingSource.amountRemaining) {
        budgetWithFunds = combo;
      }
    });

    return budgetWithFunds || options[0].value;
  }

  getBudgetFundingSourceComboOptions (
    budgetList: Budget[],
    orgEligibleStatusArray: OrganizationEligibleForGivingStatus[],
    regAuthNames: string[],
    existingBudgetId?: number,
    existingFsId?: number,
    existingPaymentProcessor?: ProcessingTypes,
    existingPaymentStatus?: PaymentStatus
  ) {
    const hideFundingSources = this.getHideFundingSources();
    const closedText = this.i18n.translate('GLOBAL:textClosed').toLowerCase();
    const enforceSameProcessor = this.shouldEnforceSameProcessor(existingPaymentStatus);
    const mappedList = budgetList.map((budget) => {
      return this.getBudgetDetailFromMap(budget.id);
    }).reduce((acc, budget) => {
      return [
        ...acc,
        ...budget.budgetFundingSources.filter((source) => {
          if (enforceSameProcessor) {
            return source.processingTypeId === existingPaymentProcessor;
          }

          return true;
        }).map((source) => {
          const processorText = source.processingTypeId === ProcessingTypes.Client ?
            this.i18n.translate(
              'GLOBAL:textClientProcessor',
              {},
              'Client processor'
            ) :
            this.i18n.translate(
              'GLOBAL:textYourCauseProcessor',
              {},
              'YourCause processor'
            );
          let label = `${budget.name} ${
              budget.isClosed ? `(${closedText}) ` : ''
            }`;
          let htmlLabel = `
              ${budget.name} ${budget.isClosed ? `(${closedText}) ` : ''}
              <small class="d-block">${processorText}</small>
            `;
          if (!hideFundingSources) {
            label = `${budget.name} ${
              budget.isClosed ? `(${closedText}) ` : ''
            }- ${source.fundingSourceName} ${
              source.isFundingSourceClosed ? `(${closedText}) ` : ''
            }`;
            htmlLabel = `
                ${budget.name} ${budget.isClosed ? `(${closedText}) ` : ''} -
                ${source.fundingSourceName} ${source.isFundingSourceClosed ? `(${closedText}) ` : ''}
                <small class="d-block">(${processorText})</small>
              `;
          }

          return {
            label,
            htmlLabel,
            value: {
              budget,
              fundingSource: source,
              isClosed: budget.isClosed || source.isFundingSourceClosed,
              comboId: budget.id + '-' + source.fundingSourceId
            }
          };
        })
      ];
    }, []);

    return this.filterForVetting(
      mappedList,
      orgEligibleStatusArray,
      regAuthNames,
      existingBudgetId,
      existingFsId
    );
  }

  /**
   * We allow edit of budget if it's proceeded past the pending status
   * The only condition is that they have to pick another budget with the same processor
   *
   * @param existingPaymentStatus: Existing Payment Status
   * @returns if we need to enforce same processor when updating the budget
   */
  shouldEnforceSameProcessor (
    existingPaymentStatus?: PaymentStatus
  ) {
    return !!existingPaymentStatus &&
      existingPaymentStatus !== PaymentStatus.Pending;
  }

  filterForVetting (
    options: TypeaheadSelectOption<BudgetFundingSourceCombo>[],
    orgEligibleStatusArray: OrganizationEligibleForGivingStatus[],
    regAuthNames: string[],
    existingBudgetId?: number,
    existingFsId?: number
  ) {
    const allOrgsAreEligibleForYcProcessing = orgEligibleStatusArray.every((status) => {
      return status === OrganizationEligibleForGivingStatus.ELIGIBLE;
    });
    const allRegistrationAuthoritiesAreValid = regAuthNames.every((name) => {
      return this.nonprofitService.isRegAuthValidForProcessing(name);
    });

    return options.filter((budgetFs) => {
      if (
        existingBudgetId === budgetFs.value.budget.id &&
        existingFsId === budgetFs.value.fundingSource.fundingSourceId
      ) {
        return true;
      }

      const isClientProcessed = budgetFs.value.fundingSource.processingTypeId === ProcessingTypes.Client;
      
      if (isClientProcessed) {
        return allRegistrationAuthoritiesAreValid;
      } else {
        return allOrgsAreEligibleForYcProcessing && allRegistrationAuthoritiesAreValid;
      }
    });
  }

  getRemainingAmountBudgetMap (
    options: TypeaheadSelectOption<BudgetFundingSourceCombo>[] = [],
    currentBudgetFs: BudgetFundingSourceCombo,
    currentPaymentAmount: number,
    originalPaymentAmount: number|string,
    isSimpleAward: boolean,
    isUnits: boolean,
    allowOverage: boolean,
    appReservedInfo: ApplicationBudgetInfo[],
    originalBudgetId: number,
    originalFsId: number
  ) {
    let map: RemainingAmountBudgetMap = {};
    const hasOverage = this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.AllowBudgetOverages);
    options.forEach((option) => {
      const budgetFundingSource = option.value;
      if (budgetFundingSource) {
        const sourceToMap = budgetFundingSource.fundingSource;
        const currentBudgetId = currentBudgetFs.budget.id;
        const currentSourceId = currentBudgetFs.fundingSource.fundingSourceId;
        const remaining = this.getRemainingAmountForBudgetFs(
          budgetFundingSource,
          currentBudgetId,
          currentSourceId,
          currentPaymentAmount,
          originalPaymentAmount,
          appReservedInfo,
          originalBudgetId,
          originalFsId
        );
        const formattedRemainingAmount = this.currencyService.formatMoney(
          remaining < 0 ? 0 : remaining
        );
        const sameBudgetAndSource = this.isSameBudgetAndSource(
          currentBudgetId,
          sourceToMap.budgetId,
          currentSourceId,
          sourceToMap.fundingSourceId
        );
        let helpText = '';
        if (sameBudgetAndSource) {
          helpText = this.i18n.translate(
            remaining < 0 ?
              'AWARDS:textZeroAvailableForPayment' :
              'AWARDS:textAmountAvailableAfterThisPayment',
            {
              amount: formattedRemainingAmount
            },
            remaining < 0 ?
              '__amount__ available for payment' :
              '__amount__ available after this payment'
          );
        } else {
          helpText = this.i18n.translate(
            'GLOBAL:textAvailableDynamic',
            {
              amount: formattedRemainingAmount
            },
            '__amount__ available'
          );
        }
        let additionalHelpText = '';
        let canMoveFunds = false;
        const isNegative = remaining < 0;
        if (sameBudgetAndSource && isNegative) {
          if (allowOverage && hasOverage && !budgetFundingSource.isClosed) {
            const availToMove = this.unallocatedSourceMap[currentSourceId];
            const canCoverAll = (remaining + availToMove) >= 0;
            const isEditPayment = !!originalPaymentAmount;
            if (
              availToMove <= 0 ||
              (!canCoverAll && isUnits)
            ) {
              additionalHelpText = !isEditPayment ? this.i18n.translate(
                'AWARDS:textSelectAnotherBudgetFsToProceed',
                {},
                'Select another budget / funding source to proceed.'
              ) : '';
            } else {
              canMoveFunds = true;
              if (isEditPayment) {
                additionalHelpText = this.i18n.translate(
                  isSimpleAward ?
                    'AWARDS:textClickSaveMoveFunds' :
                    'AWARDS:textClickApproveAwardPayMoveFunds',
                  {},
                  isSimpleAward ?
                    `Click 'Save' to move funds.` :
                    `Click 'Approve, award, and pay' to move funds.`
                );
              } else {
                if (this.getHideFundingSources()) {
                  additionalHelpText = this.i18n.translate(
                    isSimpleAward ?
                      'AWARDS:textChooseAnotherBudgetOrClickSaveMoveFunds' :
                      'AWARDS:textChooseAnotherBudgetOrClickApproveAwardPayMoveFunds',
                    {},
                    isSimpleAward ?
                      `Choose another budget or click 'Save' to move funds.` :
                      `Choose another budget or click 'Approve, award, and pay' to move funds.`
                  );
                } else {
                  additionalHelpText = this.i18n.translate(
                    isSimpleAward ?
                      'AWARDS:textChooseAnotherBudgetFsOrClickSaveMoveFunds' :
                      'AWARDS:textChooseAnotherBudgetFsOrClickApproveAwardPayMoveFunds',
                    {},
                    isSimpleAward ?
                      `Choose another budget / funding source or click 'Save' to move funds.` :
                      `Choose another budget / funding source or click 'Approve, award, and pay' to move funds.`
                  );
                }
              }
            }
          }
        }
        const comboId = option.value.comboId;
        map = {
          ...map,
          [comboId]: {
            label: helpText,
            value: remaining,
            helpDisplay: `${helpText}${
              additionalHelpText ? `. ${additionalHelpText}` : ''
            }`,
            canMoveFunds
          }
        };
      }
    });

    return map;
  }

  getRemainingAmountForBudgetFs (
    budgetFundingSource: BudgetFundingSourceCombo,
    currentBudgetId: number,
    currentSourceId: number,
    currentPaymentAmount: number,
    originalPaymentAmount: number|string,
    appReservedInfo: ApplicationBudgetInfo[],
    originalBudgetId: number,
    originalFsId: number
  ) {
    const skipClosedLogic = this.getSkipClosedLogic(
      originalPaymentAmount,
      budgetFundingSource.budget.id,
      budgetFundingSource.fundingSource.fundingSourceId,
      originalBudgetId,
      originalFsId
    );
    const isClosed = !skipClosedLogic && budgetFundingSource.isClosed;
    const sourceToMap = budgetFundingSource.fundingSource;
    const totalAmount = !isClosed ? sourceToMap.totalAmount : 0;
    const totalAmountPayments = !isClosed ? sourceToMap.totalAmountPayments : 0;
    let reservedAmount = 0;
    if (
      !isClosed &&
      sourceToMap.reservedAmount &&
      this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.ReserveFunds)
    ) {
      // Should not subtract reservations
      // if the funds were reserved for THIS app
      const reservations = (appReservedInfo || []).filter((app) => {
        return this.isSameBudgetAndSource(
          budgetFundingSource.budget.id,
          app.budgetId,
          budgetFundingSource.fundingSource.fundingSourceId,
          app.fundingSourceId
        );
      }).reduce((acc, app) => {
        return acc + app.amountReserved;
      }, 0);
      reservedAmount = sourceToMap.reservedAmount - reservations;
    }
    let pendingAmount = 0;
    const isCurrentBudgetFs = this.isSameBudgetAndSource(
      budgetFundingSource.budget.id,
      currentBudgetId,
      budgetFundingSource.fundingSource.fundingSourceId,
      currentSourceId
    );
    const budgetFsChanged = budgetFundingSource?.budget.id !== originalBudgetId ||
      budgetFundingSource.fundingSource?.fundingSourceId !== originalFsId;
    if (isCurrentBudgetFs) {
      if (isClosed) {
        return this.moneyService.toFixedNumber(totalAmount - currentPaymentAmount, 2);
      }
      if (!!originalPaymentAmount) {
        if (!budgetFsChanged) {
          pendingAmount = currentPaymentAmount - +originalPaymentAmount;
        } else {
          pendingAmount = currentPaymentAmount;
        }
      } else {
        pendingAmount = currentPaymentAmount;
      }
    }
    const value = totalAmount - totalAmountPayments - pendingAmount - reservedAmount;

    return this.moneyService.toFixedNumber(value, 2);
  }

  getSkipClosedLogic (
    originalPaymentAmount: number|string,
    selectedBudgetId: number,
    selectedFsId: number,
    originalBudgetId: number,
    originalFsId: number
  ) {
    return !!originalPaymentAmount &&
      selectedBudgetId === originalBudgetId &&
      selectedFsId === originalFsId;
  }

  // ** End For Simple Award Modal * //

  /**
   * Close the given budgets
   *
   * @param payload: Payload for Closing Budget(s)
   */
  async doCloseBudget (payload: OpenCloseBudgetAPI.CloseBudgetPayload) {
    await this.budgetResources.closeBudget(payload);
    await this.resetBudgets();
    this.clearBudgetDetails(payload.budgetIds);
  }

  /**
   * Handles closing of budgets
   *
   * @param payload: Payload for Closing Budget(s)
   */
  async closeBudget (payload: OpenCloseBudgetAPI.CloseBudgetPayload) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doCloseBudget(payload),
      this.i18n.translate(
        'BUDGET:textSuccessfullyCloseBudget',
        {},
        'Successfully closed budget'
      ),
      this.i18n.translate(
        'BUDGET:textErrorClosingBudget',
        {},
        'There was an error closing budget'
      )
    );
  }

  /**
   * Opens the modal for Open/Close Budget and Handles the Response
   *
   * @param budgets: Budgets to Open or Close
   * @param context: Open or Close?
   */
  async presentBudgetOpenCloseModal (
    budgets: Budget[],
    context: 'open'|'close'
  ) {
    let budgetDetails: BudgetDrilldownInfo;
    if (budgets.length === 1) {
      const budget = budgets[0];
      this.spinnerService.startSpinner();
      budgetDetails = await this.setBudgetDrilldownInfo(budget.id);
      this.spinnerService.stopSpinner();
    }
    const response = await this.modalFactory.open(
      OpenCloseBudgetModalComponent,
      {
        budgets,
        context,
        budgetDetails
      }
    );
    if (response) {
      switch (context) {
        case 'open':
          await this.openBudget(response as number);
          break;
        case 'close':
          await this.closeBudget(response as OpenCloseBudgetAPI.CloseBudgetPayload);
          break;
      }
    }
  }

  /**
   * Opens the given Budget
   *
   * @param budgetId: Budget ID to Open
   */
  async doOpenBudget (budgetId: number) {
    await this.budgetResources.openBudget(budgetId);
    await this.resetBudgets();
    this.clearBudgetDetails([budgetId]);
  }

  /**
   * Handles opening of a budget
   *
   * @param budgetId: Budget ID
   */
  async openBudget (budgetId: number) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doOpenBudget(budgetId),
      this.i18n.translate(
        'BUDGET:textSuccessfullyOpenedBudget',
        {},
        'Successfully opened budget'
      ),
      this.i18n.translate(
        'BUDGET:textErrorOpeningBudget',
        {},
        'There was an error opening this budget'
      )
    );
  }

  /**
   * Close the Given Funding Source
   *
   * @param fundingSourceId: Funding Source to Close
   */
  async doCloseFundingSource (fundingSourceId: number) {
    await this.budgetResources.closeFundingSource([fundingSourceId]);
    await this.resetFundingSources();
  }

  /**
   * Handles Closing the Given Funding Source
   *
   * @param fundingSourceId: Funding Source ID
   */
  async closeFundingSource (fundingSourceId: number) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doCloseFundingSource(fundingSourceId),
      this.i18n.translate(
        'BUDGET:textSuccessfullyCloseFundingSource',
        {},
        'Successfully closed funding source'
      ),
      this.i18n.translate(
        'BUDGET:textErrorClosingFundingSource',
        {},
        'There was an error closing funding source'
      )
    );
  }

  /**
   * Opens the Given Funding Source
   *
   * @param fundingSourceId: Funding Source to Open
   */
  async doOpenFundingSource (fundingSourceId: number) {
    await this.budgetResources.openFundingSource(fundingSourceId);
    await this.resetFundingSources();
  }

  /**
   * Handles Opening the Given Funding Source
   *
   * @param fundingSourceId: Funding Source ID to Open
   */
  async openFundingSource (fundingSourceId: number) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doOpenFundingSource(fundingSourceId),
      this.i18n.translate(
        'BUDGET:textSuccessfullyOpenedFundingSource',
        {},
        'Successfully opened funding source'
      ),
      this.i18n.translate(
        'BUDGET:textErrorOpeningFundingSource',
        {},
        'There was an error opening this funding source'
      )
    );
  }

  /**
   * Deletes the Given Funding Source
   *
   * @param fundingSourceId: Funding Source ID to Delete
   */
  async deleteFundingSource (fundingSourceId: number) {
    await this.budgetResources.deleteFundingSource(fundingSourceId);
    await this.resetFundingSources();
  }

  /**
   * Adapts the Audit Trail Records for Non Download
   *
   * @param items: Audit Trail Records
   * @param isFundingSource: Is this for Funding Source Audit Trail?
   * @param currentBudgetId: Current Budget ID, if applicable
   * @returns the action message for the audit trail record
   */
  getActionMessagesForAuditTrail (
    items: BudgetFundingSourceAudit[],
    isFundingSource = false,
    currentBudgetId?: number
  ) {
    return this.getActionMessagesForAuditTrailAuxillary(items, isFundingSource, currentBudgetId, false) as BudgetFundingSourceAudit[];
  }

  /**
   * Adapts the Audit Trail Records for Download
   *
   * @param items: Audit Trail Records
   * @param isFundingSource: Is this for Funding Source Audit Trail?
   * @param currentBudgetId: Current Budget ID, if applicable
   * @returns the adapted audit trail record
   */
  getActionMessagesForAuditTrailDownload (
    items: BudgetFundingSourceAudit[],
    isFundingSource = false,
    currentBudgetId?: number
  ) {
    return this.getActionMessagesForAuditTrailAuxillary(items, isFundingSource, currentBudgetId, true) as ExportBudgetFundingSourceAudit[];
  }

  /**
   * Adapts the audit trail records based on scenario
   *
   * @param items: Audit Trail Records
   * @param isFundingSource: Is this for Funding Source Audit Trail?
   * @param currentBudgetId: Current Budget ID, if applicable
   * @param forDownload: Is this for download?
   * @returns the adapted audit trail record
   */
  getActionMessagesForAuditTrailAuxillary (
    items: BudgetFundingSourceAudit[],
    isFundingSource = false,
    currentBudgetId?: number,
    forDownload = false
  ) {
    const hideFundingSources = this.getHideFundingSources();

    return items.map((item) => {
      this.getImpactDisplayAndColor(item);
      switch (item.actionType) {
        case AuditTypes.FundingSourceCreated:
          item.actionMessage = this.i18n.translate(
            'BUDGET:textFundingSourceCreated',
            {},
            'Funding source created'
          );
          break;
        case AuditTypes.BudgetCreated:
          item.impactDisplay = '';
          item.actionMessage = this.i18n.translate(
            'BUDGET:textBudgetCreated',
            {},
            'Budget created'
          );
          break;
        case AuditTypes.FundingSourceAdded:
          if (hideFundingSources) {
            item.actionMessage = this.getBudgetAmountChangedText(item.impactAmount);
          } else {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceAddedDynamic',
              {
                fundingSourceName: item.fundingSourceName
              },
              '__fundingSourceName__ added'
            );
          }
          break;
        case AuditTypes.FundingSourceAmountUpdated:
          if (hideFundingSources) {
            item.actionMessage = this.getBudgetAmountChangedText(item.impactAmount);
          } else {
            if (!!item.impactAmount) {
              item.actionMessage = this.i18n.translate(
                item.impactAmount > 0 ?
                  'BUDGET:textFundingSourceIncreased' :
                  'BUDGET:textFundingSourceDecreased',
                {},
                item.impactAmount > 0 ?
                  'Funding source increased' :
                  'Funding source decreased'
              );
            } else {
              item.actionMessage = this.i18n.translate(
                'BUDGET:textFundingSourceAmountUpdated',
                {},
                'Funding source amount updated'
              );
            }
          }
          break;
        case AuditTypes.BudgetAllocationUpdated:
          if (hideFundingSources) {
            item.actionMessage = this.getBudgetAmountChangedText(item.impactAmount);
          } else {
            if (item.impactAmount > 0) {
              item.actionMessage = this.i18n.translate(
                isFundingSource ?
                  'BUDGET:textAllocationToBudgetDynamic' :
                  'BUDGET:textFundingSourceAllocationIncreasedDynamic',
                {
                  budgetName: item.infoBudgetName,
                  fundingSourceName: item.fundingSourceName
                },
                isFundingSource ?
                  'Allocation to __budgetName__' :
                  '__fundingSourceName__ allocation increased'
              );
            } else if (item.impactAmount < 0) {
              item.actionMessage = this.i18n.translate(
                isFundingSource ?
                  'BUDGET:textAllocationToBudgetDynamic' :
                  'BUDGET:textFundingSourceAllocationDecreasedDynamic',
                {
                  budgetName: item.infoBudgetName,
                  fundingSourceName: item.fundingSourceName
                },
                isFundingSource ?
                  'Allocation to __budgetName__' :
                  '__fundingSourceName__ allocation decreased'
              );
            }
          }
          break;
        case AuditTypes.OverageUsed:
          if (isFundingSource) {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textOverageAllocationForBudgetDynamic',
              {
                budgetName: item.infoBudgetName
              },
              'Overage allocation for __budgetName__'
            );
          } else {
            item.actionMessage = this.i18n.translate(
              hideFundingSources ? 'BUDGET:textAllocationIncreasedForOverage' : 'BUDGET:textFundingSourceAllocationIncreasedForOverage',
              {
                fundingSourceName: item.fundingSourceName
              },
              hideFundingSources ?
                'Allocation increased for overage' :
                '__fundingSourceName__ allocation increased for overage'
            );
          }
          break;
        case AuditTypes.FundingSourceClosed:
          item.impactColor = '';
          if (isFundingSource) {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceClosed',
              {},
              'Funding source closed'
            );
          } else {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceClosedDynamic',
              {
                fundingSourceName: item.fundingSourceName
              },
              '__fundingSourceName__ closed'
            );
          }
          break;
        case AuditTypes.FundingSourceOpened:
          item.impactColor = '';
          if (isFundingSource) {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceOpened',
              {},
              'Funding source opened'
            );
          } else {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceOpenedDynamic',
              {
                fundingSourceName: item.fundingSourceName
              },
              '__fundingSourceName__ opened'
            );
          }
          break;
        case AuditTypes.BudgetClosed:
          item.impactColor = '';
          if (item.impactAmount === 0) {
            item.impactDisplay = '';
          }
          item.actionMessage = this.i18n.translate(
            'BUDGET:textBudgetClosed',
            {},
            'Budget closed'
          );
          break;
        case AuditTypes.BudgetOpened:
          item.impactColor = '';
          item.actionMessage = this.i18n.translate(
            'BUDGET:textBudgetOpened',
            {},
            'Budget opened'
          );
          break;
        case AuditTypes.FundsReallocated:
          item.actionMessage = this.i18n.translate(
            hideFundingSources ? 'BUDGET:textFundsReallocatedToBudget' : 'BUDGET:textFundingSourceFundsReallocatedDynamic',
            {
              fundingSourceName: item.fundingSourceName,
              budgetName: item.infoBudgetName
            },
            hideFundingSources ? 'Funds reallocated to __budgetName__' : '__fundingSourceName__ funds reallocated to __budgetName__'
          );
          break;
        case AuditTypes.FundsReturnedToSource:
          if (isFundingSource) {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundsReturnedFromClosedBudgetDynamic',
              {
                budgetName: item.infoBudgetName
              },
              'Funds returned from closed budget __budgetName__'
            );
          } else {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceFundsReturnedDynamic',
              {
                fundingSourceName: item.fundingSourceName
              },
              '__fundingSourceName__ funds returned to source'
            );
          }
          break;
        case AuditTypes.BudgetDeleted:
          item.impactColor = '';
          item.actionMessage = this.i18n.translate(
            'BUDGET:textFundsReturnedFromDeletedBudgetDynamic',
            {
              budgetName: item.infoBudgetName
            },
            'Funds returned from deleted budget __budgetName__'
          );
          break;
        case AuditTypes.FundingSourceDeleted:
          item.impactColor = '';
          if (hideFundingSources) {
            item.actionMessage = this.getBudgetAmountChangedText(item.impactAmount);
          } else {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceDeletedFromBudgetDynamic',
              {
                fundingSourceName: item.fundingSourceName
              },
              '__fundingSourceName__ deleted from budget'
            );
          }
          break;
        case AuditTypes.BudgetAllocationUpdatedFromClosedBudget:
          if (isFundingSource) {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceAllocationIncreasedViaClosingBudget',
              {
                budgetName: item.infoBudgetName
              },
              'Allocation increased via closing __budgetName__'
            );
          } else if (+item.infoBudgetId === +currentBudgetId) {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceAllocationIncreasedViaClosingBudget2',
              {
                fundingSourceName: item.fundingSourceName
              },
              '__fundingSourceName__ allocation increased via closing budget'
            );
          } else {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textFundingSourceAllocationIncreasedViaClosingBudget3',
              {
                fundingSourceName: item.fundingSourceName,
                budgetName: item.infoBudgetName
              },
              '__fundingSourceName__ allocation increased via closing __budgetName__'
            );
          }
          break;
        case AuditTypes.FundingSourceReallocationToBudgetViaClosingBudget:
          if (isFundingSource) {
            item.impactColor = '';
            // no '+' for this one per David
            item.impactDisplay = this.currencyService.formatMoney(item.impactAmount);
            item.actionMessage = this.i18n.translate(
              'BUDGET:textReallocationToNewBudgetViaClosing',
              {
                newBudgetName: item.budgetName,
                closedBudgetName: item.infoBudgetName
              },
              'Reallocation to __newBudgetName__ via closing __closedBudgetName__'
            );
          } else {
            item.actionMessage = this.i18n.translate(
              'BUDGET:textReallocationToBudgetViaClosing',
              {
                budgetName: item.infoBudgetName
              },
              'Reallocation to budget via closing __budgetName__'
            );
          }
          break;
        case AuditTypes.BudgetAccountNumberChanged:
          item.impactDisplay = '';
          item.actionMessage = item.infoBudgetAccountNumber ?
            this.i18n.translate(
              'BUDGET:textAccountNumberUpdatedDynamic',
                {
                  accountNumber: item.infoBudgetAccountNumber
                },
                'Account number updated to __accountNumber__'
              ) :
              this.i18n.translate(
                'BUDGET:textAccountNumberRemoved',
                {},
                'Account number removed'
              );
          break;
        case AuditTypes.FundingSourceNoReallocationToBudgetViaClosingBudget:
          item.impactColor = '';
          item.actionMessage = this.i18n.translate(
            'BUDGET:textBudgetClosedFundsUnavailable',
            {
              budgetName: item.infoBudgetName || item.budgetName
            },
            '__budgetName__ closed. Funds unavailable.'
          );
          break;
      }

      return !forDownload ?
        item :
        {
          date: this.dateService.formatDate(item.createdDate),
          detail: item.actionMessage,
          user: item.createdByUserName,
          impact: item.impactDisplay
        } as ExportBudgetFundingSourceAudit;
    });
  }

  /**
   * Get the Budget Amount Changed Text
   *
   * @param impactAmount: Impact Amount
   * @returns Text for the amount
   */
  getBudgetAmountChangedText (impactAmount: number) {
    return this.i18n.translate(
      impactAmount > 0 ?
        'BUDGET:textBudgetIncreased' :
        'BUDGET:textBudgetDecreased',
      {},
      impactAmount > 0 ?
        'Budget increased' :
        'Budget decreased'
    );
  }

  /**
   * Adapts the Audit trail record with impactColor and impactDisplay
   *
   * @param item: Audit Trail Record
   */
  getImpactDisplayAndColor (item: BudgetFundingSourceAudit) {
    if (item.impactAmount > 0) {
      item.impactColor = 'text-success';
      item.impactDisplay = `+${this.currencyService.formatMoney(item.impactAmount)}`;
    } else if (item.impactAmount < 0) {
      item.impactColor = 'text-danger';
      item.impactDisplay = `-${this.currencyService.formatMoney(
        Math.abs(item.impactAmount)
      )}`;
    } else {
      item.impactDisplay = this.currencyService.formatMoney(item.impactAmount);
    }
  }

  /**
   * Gets the URL to navigate to the budget or funding source detail
   *
   * @param isBudget: Is For Budget?
   * @param id: Record ID
   * @returns the URL to navigate to the budget or funding source detail
   */
  getUrlForDetail (
    isBudget: boolean,
    id: number
  ) {
    if (isBudget) {
      return this.getBudgetDetailRoute(id);
    } else  {
      return `/management/program-setup/funding-sources/${id}/audit-trail`;
    }
  }

  getBudgetImportTemplateNoFundingSources (
    ValidationClass: any
  ) {
    const csvString = this.dynamicValidationService.getSample(ValidationClass);
    this.fileService.downloadString(
      csvString,
      'text/csv',
      'template.csv'
    );
  }

  getBudgetImportTemplateWithFundingSources (
    fsIds: number[]
  ) {
    const fundingSources = this.allFundingSources.filter((fs) => fsIds.some((id) => id === fs.id));

    const headerArray = ['Budget Name', 'Budget Description'];
    fundingSources.forEach((fs) => {
      headerArray.push(fs.name);
    });

    const headerString = headerArray.join(',');

    return this.fileService.downloadString(
      headerString,
      'text/csv',
      'template.csv'
    );
  }

  getBudgetImportModel (
    fsIds: number[]
  ) {
    // No IDs means funding sources are hidden
    if (fsIds.length === 0) {
      const processorType = this.clientSettingsService.clientSettings.clientProcessingType;
      if (processorType === ProcessingTypes.Both) {
        return BudgetImportNoSourcesWithProcesserModel;
      } else {
        return BudgetImportNoSourcesModel;
      }
    } else {
      class ExtendedBudgetImportModel extends BudgetImportModel { }
      const fundingSources = this.allFundingSources.filter((fs) => fsIds.some((id) => id === fs.id));
      const validator = this.generateFundingSourceBudgetImportValidator(fundingSources);
      validator()(ExtendedBudgetImportModel);
      fundingSources.forEach((source) => {
        IsNumber({ min: 0 })(ExtendedBudgetImportModel.prototype, source.name);
        Transform((val: string) => Number(val))(ExtendedBudgetImportModel.prototype, source.name);
        AtLeastOneAllocationExists()(ExtendedBudgetImportModel.prototype, source.name);
      });

      return ExtendedBudgetImportModel;
    }
  }

  /**
   * Adapts the budgets from the import file to the model for the API when funding sources are off
   *
   * @param budgets: Imported Budgets
   * @returns the adapted model for the API
   */
  adaptBudgetImportDataNoSources (
    budgets: (BudgetImportNoSourcesModel|BudgetImportNoSourcesWithProcesserModel)[]
  ): BudgetImportNoSources {
    return {
      budgets: budgets.map((budget) => {
        const type = budget['Type (cash or in-kind)'];
        const fundingSourceType = type === 'cash' ?
          FundingSourceTypes.DOLLARS:
          FundingSourceTypes.UNITS;
        const processorSetting = this.clientSettingsService.clientSettings.clientProcessingType;
        let processor = processorSetting;
        if (processorSetting === ProcessingTypes.Both) {
          const isClientProcessed = (budget as BudgetImportNoSourcesWithProcesserModel)[
            'Processed by Client (true or false)'
          ];
          processor = isClientProcessed ? ProcessingTypes.Client : ProcessingTypes.YourCause;
        }

        return {
          budgetName: budget['Budget Name'],
          budgetDescription: budget['Budget Description'],
          totalAmount: budget['Total Amount'],
          fundingSourceType,
          processor
        };
      })
    };
  }

  /**
   * Adapts the budgets from the import file to the model for the API when funding sources are on
   *
   * @param budgetData: Imported Budgets
   * @returns the adapted model for the API
   */
  adaptBudgetImportDataWithSources (
    budgetData: BudgetImportModel[]
  ): BudgetImport {
    const budgets = budgetData.reduce((acc, curr, _index) => {
      const allocations: FundingSourceAllocationsImport[] = [];
      Object.keys(curr).forEach((key) => {
        const obj = curr as any;
        const fundingSource = this.openFundingSources.find((fs) => fs.name === key);
        const allocatedAmount = +obj[key];
        if (!!fundingSource && allocatedAmount > 0) {
          allocations.push({
            fundingSourceId: fundingSource.id,
            fundingSourceType: fundingSource.type,
            allocatedAmount
          });
        }
      });
      const budget: BudgetForImport = {
        budgetName: budgetData[_index]['Budget Name'],
        budgetDescription: budgetData[_index]['Budget Description'],
        fundingSourceAllocations: allocations
      };

      return [
        ...acc,
        budget
      ];
    }, []);

    return {
      budgets
    };
  }

  /**
   * Do Budget Import
   *
   * @param payload: Budget Import Payload
   */
  async doBudgetImport (
    payload: BudgetImport|BudgetImportNoSources
  ) {
    if (this.getHideFundingSources()) {
      await this.budgetResources.importBudgetsNoSources(payload as BudgetImportNoSources);
    } else {
      await this.budgetResources.importBudgets(payload as BudgetImport);
    }
    await Promise.all([
      this.resetBudgets(),
      this.resetFundingSources()
    ]);
  }

  /**
   * Handles Budget Import
   *
   * @param payload list of budgets with allocation for bulk add
   */
  async handleBudgetImport (payload: BudgetImport|BudgetImportNoSources) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doBudgetImport(payload),
      this.i18n.translate(
        'BUDGET:textSuccessfullyImportedBudgets',
        {},
        'Successfully imported budgets'
      ),
      this.i18n.translate(
        'BUDGET:textErrorImportingBudgets',
        {},
        'There was an error importing budgets'
      )
    );
  }

  /**
   *
   * @param fundingSources these will be used to check the validate the import file's source columns
   * @returns potential errors related to funding source allocation
   */
  private generateFundingSourceBudgetImportValidator (fundingSources: FundingSource[]) {
    return createTopLevelValidator((_arg) => (importRecords: BudgetImportModel[]):
    ValidatorReturn<TopLevelValidatorReturn> => {
      return this.validateFundingSourceAllocationsForBulkBudgetImport(fundingSources, importRecords);
    });
  }

  validateFundingSourceAllocationsForBulkBudgetImport (
    fundingSources: FundingSource[],
    importRecords: BudgetImportModel[]
  ) {
    const defaultCurrency = this.clientSettingsService.defaultCurrency;
    const errors = fundingSources.reduce<TopLevelValidatorReturn[]>((acc, fs) => {
      const allocationAggregate = importRecords.reduce((aggregate, rec) => {
        const record: any = rec;

        return +record[fs.name] + aggregate;
      }, 0);
      const hasAllocationError = allocationAggregate > fs.amountUnallocated;
      if (hasAllocationError) {
        const amountUnallocated = this.currencyService.formatMoney(
          fs.amountUnallocated,
          defaultCurrency
        );
        const amountAllocatedInImport = this.currencyService.formatMoney(
          allocationAggregate,
          defaultCurrency
        );
        const error: TopLevelValidatorReturn = {
          prop: fs.name,
          i18nKey: 'BUDGET:textBudgetImportFundingSourceError2',
          defaultValue: 'Allocation exceeds available funds for __fsName__. Allocated in this import: __amountAllocatedInImport__. Total Available: __amountUnallocated__',
          context: {
            fsName: fs.name,
            amountUnallocated,
            amountAllocatedInImport
          }
        };

        return [
          ...acc,
          error
        ];
      }

      return acc;
    }, [] as TopLevelValidatorReturn[]);

    return errors;
  }

  /**
   * Return budget helpers given a budget detail. Used when hideFundingSources = true;
   *
   * @param budget: Budget detail
   * @returns some budget helpers
   */
  getBudgetHelpersWhenHidingSources (budget: BudgetDetail) {
    if (this.getHideFundingSources()) {
      let fundingSourceType = FundingSourceTypes.DOLLARS;
      const clientProcessingType = this.clientSettingsService.clientSettings.clientProcessingType;

      let processor: ProcessingTypes = clientProcessingType === ProcessingTypes.Both ?
        ProcessingTypes.YourCause :
        clientProcessingType;
      let budgetTotal = 0;
      // By default, budget total has to be greater than 0
      let minimumBudgetTotal = .01;

      if (!!budget) {
        const budgetFs = budget.budgetFundingSources[0];
        // If we hide funding sources, there is only 1 funding source
        if (!!budgetFs) {
          fundingSourceType = budgetFs.fundingSourceType;
          processor = budgetFs.processingTypeId;
          budgetTotal = budgetFs.totalAmount;
          // If budget has payments, minimum now becomes the total of those payments
          minimumBudgetTotal = budgetFs.totalAmountPayments;
        }
      }

      return {
        fundingSourceType,
        budgetTotal,
        minimumBudgetTotal,
        processor
      };
    }

    return null;
  }

  /**
   * Adds the model to the budgetMap with 'new' as the identifier
   *
   * @param response: Create Edit Budget Modal Response
   */
  addNewBudgetToDetailMap (
    response: CreateEditBudgetModalResponse
  ) {
    const budgetDetail: BudgetDetail = {
      id: null,
      name: response.name,
      description: response.description,
      accountNumber: response.accountNumber,
      budgetFundingSources: [],
      budgetTags: response.budgetTags,
      isClosed: false
    };
    this.addBudgetDetailToMap(budgetDetail, true);
  }

  /**
   * Handles saving a budget with funding sources turned on
   *
   * @param response: Create Edit Budget Modal Response
   * @param budgetId: Budget ID
   * @returns the ID of the budget
   */
  async handleBudgetSaveWithSources (
    response: CreateEditBudgetModalResponse,
    budgetId: number
  ): Promise<number|string> {
    const isNew = !budgetId;
    if (isNew) {
      // New Budgets with Funding Sources are taken to the Budget Detail page
      // they must select at least 1 funding source in order to create the budget
      this.addNewBudgetToDetailMap(response);

      return 'new';
    } else {
      const existingBudget = this.getBudgetDetailFromMap(budgetId);
      const sources = existingBudget.budgetFundingSources;
      const payload = this.adaptUiBudgetToApiBudget(
        budgetId,
        response.name,
        response.description,
        response.accountNumber,
        sources
      );

      return this.doSaveBudget(payload, response.budgetTags, false, true);
    }
  }

  /**
   * Handles Saving of the Budget when Funding Sources are Turned Off
   *
   * @param response: Create Edit Budget Modal Response
   * @param budgetId: Budget ID
   * @returns the ID of the budget
   */
  async handleBudgetSaveNoSources (
    response: CreateEditBudgetModalResponse,
    budgetId: number
  ): Promise<number|string> {
    const budget = this.getBudgetDetailFromMap(budgetId);
    const isNew = !budgetId;
    let fundingSources: (BudgetFundingSource|BudgetFundingSourceSave)[] = budget ? budget.budgetFundingSources : [];
    let amountWasDecreased = false;
    if (!isNew) {
      const source = fundingSources[0];
      amountWasDecreased = response.budgetTotal < source.totalAmount;
      // If we are decreasing the total on the budget,
      // we need to first save the new allocation on the budget,
      // so the decrease in funding source is allowed
      if (amountWasDecreased) {
        await this.adaptAndSaveBudget(
          budgetId,
          response.name,
          response.description,
          response.accountNumber,
          [{
            ...source,
            totalAmount: response.budgetTotal,
            processingTypeId: response.processor
          }],
          true,
          !!budgetId,
          response.budgetTags
        );
      }
    }
    // Keep the hidden funding source in sync with this budget
    // Update the single funding source tied to the budget to match the budget total
    // In this scenario we create or update one funding source that represents the budget amount
    const fundingSource = this.adaptBudgetModalResponseToNewOrExistingSource(response, budgetId);
    const fundingSourceId = await this.budgetResources.saveFundingSource(fundingSource);
    fundingSources = [{
      fundingSourceId,
      fundingSourceType: response.fundingSourceType,
      totalAmount: response.budgetTotal
    }];

    if (amountWasDecreased) {
      // In this case, we already saved the budget above
      return budgetId;
    } else {
      return this.adaptAndSaveBudget(
        budgetId,
        response.name,
        response.description,
        response.accountNumber,
        fundingSources,
        true,
        !!budgetId,
        response.budgetTags
      );
    }

  }

  /**
   * Adapts the info and saves the budget
   *
   * @param budgetId: Budget ID
   * @param name: Budget Name
   * @param description: Budget Description
   * @param accountNumber: Budget Account Number
   * @param fundingSources: Budget Funding Sources
   * @param resetSources: Should we Reset Funding Sources?
   * @param resetBudgetDetail: Should we Reset the Budget Detail?
   * @returns budget ID
   */
  adaptAndSaveBudget (
    budgetId: number,
    name: string,
    description: string,
    accountNumber: string,
    fundingSources: (BudgetFundingSource | BudgetFundingSourceSave)[],
    resetSources: boolean,
    resetBudgetDetail: boolean,
    tagIds: number[]
  ) {
    const payload = this.adaptUiBudgetToApiBudget(
      budgetId,
      name,
      description,
      accountNumber,
      fundingSources
    );

    return this.doSaveBudget(payload, tagIds, resetSources, resetBudgetDetail);
  }

  /**
   * Adapts the info to the budget api model for save
   *
   * @param id: Budget ID
   * @param name: Budget Name
   * @param description: Budget Description
   * @param accountNumber: Budget Account Number
   * @param fundingSources: Budget Funding Sources
   * @returns the payload to send to the API
   */
  adaptUiBudgetToApiBudget (
    id: number,
    name: string,
    description: string,
    accountNumber: string,
    fundingSources: (BudgetFundingSource|BudgetFundingSourceSave)[]
  ): BudgetSave {
    return {
      id,
      description,
      name,
      fundingSources: fundingSources.map((source) => {
        return {
          fundingSourceId: source.fundingSourceId,
          fundingSourceType: source.fundingSourceType,
          totalAmount: source.totalAmount
        };
      }),
      accountNumber
    };
  }

  /**
   * Saves the Budget Changes
   *
   * @param payload: Payload for Budget Save
   * @param resetSources: Should we reset sources?
   * @param resetBudgetDetail: Should we reset budget detail?
   * @returns the budget ID
   */
  async doSaveBudget (
    payload: BudgetSave,
    tagIds: number[],
    resetSources = false,
    resetBudgetDetail = false
  ) {
    const id = await this.budgetResources.saveBudget(payload);
    if (!!tagIds) {
      await this.systemTagsService.handleSetTagsOnRecord(
        this.getCurrentTagIds(id),
        tagIds,
        id,
        SystemTags.Buckets.Budget,
        null,
        null,
        true
      );
    }
    await Promise.all([
      resetSources ? this.resetFundingSources() : null,
      resetBudgetDetail ? this.resetBudgetDetail(payload.id) : null,
      resetBudgetDetail ? this.resetBudgetAuditTrail(payload.id) : null,
      this.resetBudgets()
    ]);
    this.removeNewBudgetDetailFromMap();
    this.resetBudgetsForDashboard();

    return id;
  }

  /**
   * Get the Budget's Tag IDs
   *
   * @param budgetId: Budget ID
   * @returns the tags for the budget
   */
  getCurrentTagIds (budgetId: number) {
    const currentTags = this.budgetTagsMap[budgetId];
    if (currentTags?.length > 0) {
      return currentTags.map((tag) => tag.id);
    }

    return [];
  }

  /**
   * Adapts the info and returns the model for creating or updating a funding source
   *
   * @param response: Create Edit Budget Modal Response
   * @param budgetId: Budget ID
   * @returns the API model for creating or updating a funding sources
   */
  adaptBudgetModalResponseToNewOrExistingSource (
    response: CreateEditBudgetModalResponse,
    budgetId: number
  ): FundingSourceForApi {
    const budget = budgetId ? this.getBudgetDetailFromMap(budgetId) : null;
    const source = budgetId ? budget.budgetFundingSources[0] : null;

    return {
      id: source?.fundingSourceId,
      name: source?.fundingSourceName ?? response.name,
      type: response.fundingSourceType,
      totalAmount: response.budgetTotal,
      processingTypeId: response.processor
    };
  }

  /**
   * Handles Creating or Editing a Budget
   *
   * @param response: Create Edit Budget Modal Response
   * @param budgetId: Budget ID
   * @returns the budget ID
   */
  handleBudgetCreateOrEdit (
    response: CreateEditBudgetModalResponse,
    budgetId: number
  ): Promise<number|string> {
    if (this.getHideFundingSources()) {
      return this.handleBudgetSaveNoSources(response, budgetId);
    } else {
      return this.handleBudgetSaveWithSources(response, budgetId);
    }
  }

  /**
   * Handles the Budget Modal Response on Close
   *
   * @param response: Create Edit Budget Modal Response
   * @param budgetId: Budget ID
   */
  async handleBudgetModalResponse (
    response: CreateEditBudgetModalResponse,
    budgetId: number,
    doNotNavigateToNewBudget = false
  ) {
    const isNew = !budgetId;
    // For new budgets that have funding sources,
    // We don't create until they are taken to the budget detail and add their 1st funding source
    const didNotCreateYet = isNew && !this.getHideFundingSources();

    const {
      passed,
      endpointResponse
    } = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.handleBudgetCreateOrEdit(response, budgetId),
      didNotCreateYet ? '' : this.getBudgetSaveSuccess(),
      this.getBudgetSaveError()
    );

    if (passed && isNew && !doNotNavigateToNewBudget) {
      this.navigateToBudgetDetail(endpointResponse);
    }

    return endpointResponse;
  }

  /**
   * Routes to the budget detail
   *
   * @param budgetId: Budget ID to navigate to
   */
  navigateToBudgetDetail (budgetId: number|string) {
    if (!!this.gcFlyoutService.currentIdForFlyout) {
      this.flyoutService.closeFlyout();
    }
    const route = this.getBudgetDetailRoute(budgetId);
    this.router.navigate([route]);
  }

  /**
   * Routes to the budget insights
   *
   * @param budgetId: Budget ID to navigate to
   */
  navigateToBudgetInsights (budgetId: number) {
    if (!!this.gcFlyoutService.currentIdForFlyout) {
      this.flyoutService.closeFlyout();
    }
    this.router.navigate([`/management/insights/budgets/budget/${budgetId}`]);
  }

  /**
   * Routes to the funding source insights
   *
   * @param fundingSourceId: Source ID to navigate to
   */
  navigateToFundingSourceInsights (fundingSourceId: number) {
    if (!!this.gcFlyoutService.currentIdForFlyout) {
      this.flyoutService.closeFlyout();
    }
    this.router.navigate([`/management/insights/funding-sources/funding-source/${fundingSourceId}`]);
  }

  /**
   * Returns the route to navigate to the budget detail
   *
   * @param budgetId: Budget ID
   * @returns the route to get to the budget detail
   */
  getBudgetDetailRoute (budgetId: number|string) {
    return `/management/program-setup/budgets/${budgetId}/${this.getHideFundingSources() ? 'audit-trail' : 'funding-sources'}`;
  }

  /**
   * Updates the funding sources on a budget
   *
   * @param budgetId: Budget ID
   * @param sources: Funding Sources to Update
   * @returns the budget ID
   */
  async doUpdateBudgetFundingSources (
    budgetId: number,
    sources: (BudgetFundingSource|BudgetFundingSourceSave)[]
  ) {
    const isExisting = !!budgetId;
    const budget = this.getBudgetDetailFromMap(budgetId || 'new');
    const payload = this.adaptUiBudgetToApiBudget(
      budgetId,
      budget.name,
      budget.description,
      budget.accountNumber,
      sources
    );
    // Only scenario for saving tags here is when the budget is new
    const budgetTags = isExisting ? null : budget.budgetTags;

    return this.doSaveBudget(payload, budgetTags, true, isExisting);
  }

  /**
   * Handles Updating the Budget's Funding Sources
   *
   * @param budgetId: Budget ID
   * @param fundingSources: Funding Sources
   * @returns the budget ID
   */
  async handleUpdateBudgetFundingSources (
    budgetId: number,
    fundingSources: (BudgetFundingSource|BudgetFundingSourceSave)[]
  ): Promise<number> {
    const {
      passed,
      endpointResponse
    } = await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doUpdateBudgetFundingSources(budgetId, fundingSources),
      this.getBudgetSaveSuccess(),
      this.getBudgetSaveError()
    );
    if (passed) {
      if (!budgetId) {
        // navigate to newly created budget
        this.navigateToBudgetDetail(endpointResponse);
      }

      return endpointResponse;
    } else {
      return null;
    }
  }

  /**
   * Budget Save Success Toastr Text
   *
   * @returns Budget save success message
   */
  getBudgetSaveSuccess () {
    return this.i18n.translate(
      'BUDGET:textSuccessfullySavedBudget',
      {},
      'Successfully saved the budget'
    );
  }

  /**
   * Budget Save Error Toastr Text
   *
   * @returns Budget save error message
   */
  getBudgetSaveError () {
    return this.i18n.translate(
      'BUDGET:textErrorSavingBudget',
      {},
      'There was an error saving the budget'
    );
  }

  /**
   * Determines whether the default budgets are still valid
   *
   * @param budgetIds: Budget IDs selected
   * @param defaultCashBudgetId: Default Cash Budget ID
   * @param defaultInKindBudgetId: Default In-Kind Budget ID
   * @returns whether the default budgets are still valid
   */
  areBudgetsStillValid (
    budgetIds: number[],
    defaultCashBudgetId: number,
    defaultInKindBudgetId: number
  ) {
    const cashInvalid = defaultCashBudgetId && !budgetIds.includes(defaultCashBudgetId);
    const inKindInvalid = defaultInKindBudgetId && !budgetIds.includes(defaultInKindBudgetId);

    return {
      cashInvalid,
      inKindInvalid
    };
  }

  /**
   * Do the bulk update of budgets and reset necessary data
   *
   * @param payload: Bulk Update Budgets Payload
   */
  async doBulkBudgetUpdate (payload: BulkUpdateBudgetsPayload) {
    await this.budgetResources.bulkUpdateBudgets(payload);
    this.clearBudgetDetails(payload.budgetFundingSources.map((budget) => budget.budgetId));
    await Promise.all([
      this.resetBudgets(),
      this.resetFundingSources()
    ]);
  }

  /**
   * Handles bulk budget updates
   *
   * @param payload: Bulk Update Budgets Payload
   */
  async handleBulkBudgetUpdate (payload: BulkUpdateBudgetsPayload) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doBulkBudgetUpdate(payload),
      this.i18n.translate(
        'BUDGET:textSuccessfullySavedBudgetChanges',
        {},
        'Successfully saved budget changes'
      ),
      this.i18n.translate(
        'BUDGET:textErrorSavingBudgetChanges',
        {},
        'There was an error saving budget changes'
      )
    );
  }

  /**
   * Deletes the given budget
   *
   * @param budget: Budget to Delete
   */
  async doBudgetDelete (budget: Budget) {
    let fundingSourceIdToDelete: number = null;
    if (this.getHideFundingSources()) {
      // in this case we also delete the funding source
      const detail = await this.setBudgetDetail(budget.id);
      // only one funding source will exist in this scenario
      fundingSourceIdToDelete = detail.budgetFundingSources[0].fundingSourceId;
    }
    await this.budgetResources.deleteBudget(budget.id);
    await Promise.all([
      this.resetBudgets(),
      !!fundingSourceIdToDelete ?
        this.deleteFundingSource(fundingSourceIdToDelete) : // delete calls reset
        this.resetFundingSources()
    ]);
  }

  /**
   * Handles confirm modal and deletion of the budget
   *
   * @param budget: Budget to delete
   */
  async handleDeleteBudget (budget: Budget) {
    const {
      passed,
      error
    } = await this.confirmAndTakeActionService.genericConfirmAndTakeAction(
      () => this.doBudgetDelete(budget),
      this.i18n.translate('BUDGET:hdrDeleteBudget', {}, 'Delete Budget'),
      budget.name,
      this.i18n.translate(
        'BUDGET:textAreYouSureDeleteBudget',
        {},
        'Are you sure you want to delete this budget?'
      ),
      this.i18n.translate('common:btnDelete', {}, 'Delete'),
      this.i18n.translate(
        'BUDGET:textSuccessfullyDeletedBudget',
        {},
        'Successfully deleted the budget'
      ),
      ''
    );
    if (!passed && !!error) {
      const isProgramError = (error as any)?.error?.message?.includes(
        'This budget is currently tied to the following Grant Program(s)'
      );
      if (isProgramError) {
        this.notifier.error(this.i18n.translate(
          'BUDGET:textErrorDeletingBudgetRelatedToProgram',
          {},
          'This budget cannot be deleted because it is currently related to one or more grant programs.'
        ));
      } else {
        this.notifier.error(this.i18n.translate(
          'BUDGET:textErrorDeletingBudget',
          {},
          'There was an error deleting the budget'
        ));
      }
    }
  }

  /**
   * Does the download of the budget audit trail
   *
   * @param budgetId: Budget ID
   * @param downloadFormat: Download Format
   */
  async doDownloadBudgetAuditTrail (
    budgetId: number,
    downloadFormat: TableDataDownloadFormat
  ) {
    const tableKey = this.getTableKeyForAuditTrail(budgetId, false);
    const currentRepo = this.tableFactory.getRepository(tableKey);
    let filteredRows: BudgetFundingSourceAudit[] = [];
    if (!!currentRepo) {
      filteredRows = currentRepo.clientSideFilteredRows as BudgetFundingSourceAudit[];
    } else {
      await this.setBudgetAuditTrailRecords(budgetId);
      filteredRows = this.getBudgetAuditTrailFromMap(budgetId);
    }
    const adaptedRows = this.getActionMessagesForAuditTrailDownload(filteredRows, false, budgetId);
    const csv = unparse(adaptedRows);

    this.fileService.downloadByFormat(csv, downloadFormat);
  }

  /**
   * Downloads the Budget Audit Trail
   *
   * @param budgetId: Budget ID
   * @param downloadFormat: Download Format
   */
  async downloadBudgetAuditTrail (
    budgetId: number,
    downloadFormat: TableDataDownloadFormat
  ) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doDownloadBudgetAuditTrail(budgetId, downloadFormat),
      this.i18n.translate(
        'BUDGET:textSuccessfullyExportedBudgetAuditTrail',
        {},
        'Successfully exported budget audit trail'
      ),
      this.i18n.translate(
        'BUDGET:textErrorExportingBudgetAuditTrail',
        {},
        'There was an error exporting the budget audit trail'
      )
    );
  }

  /**
   * Gets the table key for the budget audit trail table
   *
   * @param budgetId: Budget ID
   * @param isSimple: Is this a simple table?
   * @returns the table key for the audit trail
   */
  getTableKeyForAuditTrail (
    budgetId: number,
    isSimple: boolean
  ) {
    return `${Budgets_Table_Key}_${budgetId}_${isSimple ? 'Simple' : ''}`;
  }

  /**
   * Gets the audit trail records for the given budget
   *
   * @param budgetId: Budget ID
   * @returns the audit trail records for this budget
   */
  getBudgetAuditTrailFromMap (budgetId: number) {
    return this.budgetAuditTrailMap[budgetId];
  }

  /**
   * Sets the budget audit trail records
   *
   * @param budgetId: Budget ID
   * @param auditTrail: Audit trail records
   */
  setBudgetAuditTrailMap (budgetId: number, auditTrail: BudgetFundingSourceAudit[]) {
    this.set('budgetAuditTrailMap', {
      ...this.budgetAuditTrailMap,
      [budgetId]: auditTrail
    });
  }

  /**
   * Fetches and sets the budget's audit trail records
   *
   * @param budgetId: Budget ID
   */
  async setBudgetAuditTrailRecords (
    budgetId: number
  ) {
    if (!this.getBudgetAuditTrailFromMap(budgetId)) {
      const paginationOptions: PaginationOptions<BudgetFundingSourceAudit> = {
        rowsPerPage: 15,
        pageNumber: 0,
        sortColumns: [{
          columnName: 'createdDate',
          sortAscending: false
        }],
        filterColumns: [],
        retrieveTotalRecordCount: true,
        returnAll: true
      };
      const result = await this.budgetResources.getBudgetAuditTrail(
        budgetId,
        paginationOptions
      );

      const adapted = this.getActionMessagesForAuditTrail(result.records);
      this.setBudgetAuditTrailMap(budgetId, adapted);
    }
  }

  /**
   * Opens the budget flyout for the given record
   *
   * @param budget: Budget to open flyout for
   */
  async openBudgetDetailFlyout (budget: Budget) {
    this.gcFlyoutService.setInfoForFlyout(budget, Budgets_Table_Key, 'id');
    const only1Record = this.gcFlyoutService.idsForFlyout.length === 1;
    await this.flyoutService.openFlyout(
      BudgetDetailFlyoutComponent,
      {
        defaultWidth: 600,
        showIterator: !only1Record
      },
      this.gcFlyoutService.onNextFlyoutRecord,
      this.gcFlyoutService.onPreviousFlyoutRecord,
      this.prepareBudgetDetailsForFlyout,
      this.gcFlyoutService.onInitialFlyoutRecord
    );
  }

  /**
   * Opens the funding source flyout for the given record
   *
   * @param fundingSource: Funding source to open flyout for
   */
  async openFundingSourceDetailFlyout (fundingSource: FundingSource) {
    this.gcFlyoutService.setInfoForFlyout(fundingSource, Funding_Source_Table_Key, 'id');
    const only1Record = this.gcFlyoutService.idsForFlyout.length === 1;
    await this.flyoutService.openFlyout(
      FundingSourceDetailFlyoutComponent,
      {
        defaultWidth: 600,
        showIterator: !only1Record
      },
      this.gcFlyoutService.onNextFlyoutRecord,
      this.gcFlyoutService.onPreviousFlyoutRecord,
      this.prepareFundingSourceDetailsForFlyout,
      this.gcFlyoutService.onInitialFlyoutRecord
    );
  }

  /**
   * Fetches the necessary data for budget flyout
   *
   * @param budgetId: Budget ID
   */
 prepareBudgetDetailsForFlyout = async (budgetId: number|string) => {
    await Promise.all([
      this.setBudgetAuditTrailRecords(+budgetId),
      this.setBudgetDetail(+budgetId),
      this.setBudgetDrilldownInfo(+budgetId)
    ]);
  };

  /**
   * Fetches the necessary data for funding source flyout
   *
   * @param fundingSourceId: Funding Source ID
   */
  prepareFundingSourceDetailsForFlyout = async (fundingSourceId: number|string) => {
    await this.getFundingSourceDrilldownInfo(+fundingSourceId);
  };

  /**
   * Saves the Funding Source
   *
   * @param fundingSource: Funding Source to Save
   */
  async doSaveFundingSource (fundingSource: FundingSource) {
    const fundingSourceForApi: FundingSourceForApi = {
      id: fundingSource.id,
      name: fundingSource.name,
      type: fundingSource.type,
      totalAmount: fundingSource.totalAmount,
      processingTypeId: fundingSource.processingTypeId
    };
    await this.budgetResources.saveFundingSource(fundingSourceForApi);
    await this.resetFundingSources();
  }

  /**
   * Handles saving a funding source
   *
   * @param fundingSource: Funding source to save
   */
  async handleSaveFundingSource (
    fundingSource: FundingSource
  ) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doSaveFundingSource(fundingSource),
      this.i18n.translate(
        fundingSource.id ?
          'BUDGET:textSuccessfullyUpdateFundingSource' :
          'BUDGET:textSuccessfullyAddFundingSource',
        {},
        fundingSource.id ?
          'Successfully updated the funding source' :
          'Successfully added the funding source'
      ),
      this.i18n.translate(
        fundingSource.id ?
          'BUDGET:textErrorUpdatingTheFundingSource' :
          'BUDGET:textErrorAddingTheFundingSource',
        {},
        fundingSource.id ?
          'There was an error updating the funding source' :
          'There was an error adding the funding source'
      )
    );
  }

  /**
   * @param _value: value to validate
   * @param extras form field validation extras
   * @returns if error - error object, otherwise empty array
   */
  validateAtLeastOneAllocationToBudget (_value: any, extras: ValidatorExtras<any, any, any>): ValidatorReturn {
    const fundingSourceIds = extras.externalContext.fundingSourceIds as number[];
    // Ensure the budget has at least one allocation greater than 0
    let hasAllocation = false;
    fundingSourceIds.forEach((sourceId) => {
      const sourceName = this.fundingSourceMap[sourceId]?.name;
      if (!!sourceName) {
        const allocation = extras.ent[sourceName];
        if (allocation > 0) {
          hasAllocation = true;
        }
      }
    });
    if (!hasAllocation) {
      return {
        i18nKey: 'BUDGET:textMustHaveAtLeastOneAllocation',
        defaultValue: 'Budget must have at least one allocation'
      };
    }

    return [];
  }
}


export const AtLeastOneAllocationExists = ServiceValidator(
  BudgetService,
  'validateAtLeastOneAllocationToBudget'
);