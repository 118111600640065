import { Injectable } from '@angular/core';
import { CsrConnectStats } from '../typings/organization.typing';
import { HttpRestService } from './http-rest.service';
import { MoneyService } from '@yourcause/common/currency';

@Injectable({ providedIn: 'root' })
export class AggregationService {

  constructor (
    private httpRestService: HttpRestService,
    private moneyService: MoneyService
  ) { }

  getCharityDonationsByYear (charityId: number): Promise<CsrConnectStats> {
    return this.httpRestService.get('api/aggregations/charities/' + charityId + '/activity');
  }


  shortNumberFilter (number: number, precision = 0) {
    let result;
    const powerOfTen = Math.floor(Math.log(Math.abs(number)) * Math.LOG10E);

    switch (powerOfTen) {
      case 3:
      case 4:
      case 5:
        result = this.moneyService.toFixedString(number / Math.pow(10, 3), precision) + 'K';
        break;
      case 6:
      case 7:
      case 8:
        result = this.moneyService.toFixedString(number / Math.pow(10, 6), precision) + 'M';
        break;
      case 9:
      case 10:
      case 11:
        result = this.moneyService.toFixedString(number / Math.pow(10, 9), precision) + 'B';
        break;
      case 12:
      case 13:
      case 14:
        result = this.moneyService.toFixedString(number / Math.pow(10, 12), precision) + 'T';
        break;
      default:
        result = this.moneyService.toFixedString(number, precision);
    }

    if (result === ('' + number)) {
      return this.moneyService.toFixedString(+number, 0);
    }

    return result;
  }

}
